export const FREE_RESEARCH_LIMIT = 2;
export const FREE_RESEARCH_ASSETS_LIMIT = 3;
export const FREE_RESEARCH_MESSAGE_LIMIT = 200;
export const PAID_RESEARCH_ASSETS_LIMIT = 50;
export const DEFAULT_BRANDING = {
    companyName: "", 
    primaryColor: "5E28D1",
    primaryBg: "EAE0FF",
    foregroundColor: "000000",
    backgroundColor: "FFFFFF",
    borderColor: "EAE0FF"
}

// Embedding Chunks Constants

export const AI_SUMMARY_CHUNKS = 5;
export const AI_REPLY_CHUNKS = 2;
export const AI_INSIGHTS_CHUNKS = 3;
export const AI_STATEMENTS_CHUNKS = 4;
