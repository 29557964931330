import React from 'react';
import { ConfigProvider, theme, Row, Col, Card } from 'antd';
import styles from "./ChatBubble.module.css";
const { useToken } = theme;
export function ChatBubble({ status, text, extra }){
    const { token } = useToken()
    if(status === "sent"){
    return(
            <Row justify="end" style={{marginBottom: '12px'}}>
            <Col style={{maxWidth: "80%"}}>
                <ConfigProvider
                    theme={{
                        token:{
                            colorBgContainer: token.colorPrimaryBg,
                            colorText: token.colorPrimaryTextHover
                        }
                    }}>
                    <Card className={styles.senderBubble}>
                        {text}
                    </Card>
                </ConfigProvider>
            </Col>
        </Row>
        
        )
    }
    else{
        return(
            <Row justify="start" style={{marginBottom: '12px'}}>
            <Col style={{maxWidth: "80%"}}>
                <ConfigProvider
                    theme={{
                        token:{
                            colorBgContainer: token.colorBgContainer,
                            colorText: token.colorText
                        }
                    }}>
                    <Card actions={[extra]} className={styles.botBubble}>
                        { text }
                    </Card>
                </ConfigProvider>
            </Col>
        </Row>
        
        )
    }
}