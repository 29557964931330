import React, { useState, useEffect } from 'react'
import { UserAuth } from '../../context/AuthContext';
import { Row, Button, Space, Col, Card, theme, Tag, Spin, Radio, notification } from 'antd';
import { CheckCircleTwoTone, ExclamationCircleTwoTone, FileOutlined, HomeOutlined } from "@ant-design/icons";
import { db, dbRef, set, onValue } from "../../firebase_setup/firebase";
import { motion } from "framer-motion";
import { NotionIcon } from "../../utils";
import { api } from "../../utils";
import { Link, useNavigate } from 'react-router-dom';
import styles from "./Settings.module.css";

const { useToken } = theme;

export function NotionIntegration() {
  
  const { token } = useToken();
  const { user, userData } = UserAuth();

  const [isConnecting, setIsConnecting] = useState(false);
  const [notionPages, setNotionPages] = useState([]);
  const [authorizationCode, setAuthorizationCode] = useState(null);
  const [accessToken, setAccessToken] = useState(false);
  const [selectedPageId, setSelectedPageId] = useState(null);

  const navigate = useNavigate();
  
  const [notificationApi, contextHolder] = notification.useNotification();
  const openNotification = ( message, description, icon ) => {
        notificationApi.open({
            message: message,
            description: description,
            icon: icon,
            duration: 3,
        });
  };
  const checkNotionToken = async ( userId ) => {
    try {
      const response = await api.post('/api/checkNotionToken', { userId });
      if(response){
        setAccessToken(response.data.notionToken);
      }
    } catch (error){
      console.error(error);
    }
  }
  const handleAccessTokenGeneration = async () => {
    try {
      setIsConnecting(true);
      if(userData && userData.notion_db_id){
        return;
      } else{
          if(accessToken){
            return;
          } else {
            await api.post('/api/notionExchange', { code: authorizationCode, user });
            const checkToken = await checkNotionToken(user.uid);
            if(checkToken){
              setAccessToken(checkToken.data.notionToken);
            }
          }
        }
      setIsConnecting(false);
    } catch (error) {
      console.error(error);
    }
  }
  const getNotionPages = async () => {
    try {
      const response = await api.get(`/api/notionPagesFetch/?userId=${user.uid}`, ); // This is a custom axios method that makes a get request to the api with api url already set
      if(response){
        setNotionPages(response.data.pages);
      }
    } catch (error){
      console.error(error);
    }
  }
  const handlePageSelection = async (e) => {
    
      setSelectedPageId(e.target.value);
    
  }
  const handleIntegrationSubmit = async () => {
    try {
      if(selectedPageId){
        const notionPageRef = dbRef(db, `users/${user.uid}/notion_page_id`);
        await set(notionPageRef, selectedPageId);
        openNotification("Notion Integration", "Notion Integration Successful", <CheckCircleTwoTone twoToneColor={token.colorSuccess} />);
        navigate('/settings');
      }
    } catch (error){
      console.error(error);
      openNotification("Notion Integration", "Notion Integration Failed", <ExclamationCircleTwoTone twoToneColor={token.colorError} />);
    }
  }
  useEffect(() => {
    if(user){
      const userId = user.id
      checkNotionToken(userId);
    }
    
  }, [user])
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      setAuthorizationCode(code);
    } else {
      navigate('/settings');
    }
  }, []);
  
  useEffect(() => {
    if(userData && ( userData.notion_db_id || userData.notion_page_id )){
      navigate('/settings');
    } else{
      if(accessToken){
        getNotionPages();
      }
    }
  }, [accessToken, userData]);
  useEffect(() => {
    if (authorizationCode) {
      handleAccessTokenGeneration();
    }
  }, [authorizationCode]);

  return (
    <div
      className={styles.profileContainer}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        flexGrow: 1,
      }}
    >
      <>
      {contextHolder}
        <Row gutter={16} justify="center" align="middle" style={{ height: "100%", textAlign: "center"}} >
          <Col xxl={8} xl={10} lg={12} md={16} xs={24}>
            <motion.div layout initial={{ opacity: 0, scale: 0.7 }} whileInView={{ opacity: 1, scale:1 }}>
              <Card>
                  <Space direction='vertical' style={{ alignItems: "center", width: "100%" }}>
                    <img src={NotionIcon} alt="Notion" style={{ width: "64px" }} />
                      {
                        isConnecting ? (
                          <Space direction='vertical' style={{ alignItems: "center" }}>
                            <h3>Connecting Notion</h3>
                            <Spin size='large' spinning={isConnecting}/>
                          </Space>
                        ) : (
                          <Row justify="center">
                            <Space direction='vertical' style={{ alignItems: "center" }}>
                          <h3>Select Notion Page for Export</h3>
                          <Radio.Group 
                            onChange={(e) => handlePageSelection(e)}
                            defaultValue={selectedPageId}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "220px", marginBottom: 16 }}>
                            {
                              notionPages && notionPages.map(page => (
                                <Radio style={{ width: "100%", height: 24 }} key={page.id} value={page.id} onChange={() => setSelectedPageId(page.id)}>
                                  <FileOutlined style={{ color: token.colorPrimary }} /> {page.properties.title ? page.properties.title.title[0].plain_text : page.properties.Name.title[0].plain_text}
                                </Radio>
                              ))
                            }
                            </div>
                          </Radio.Group>
                          <Button disabled={!selectedPageId} type="primary" onClick={handleIntegrationSubmit}>Finish Integration</Button>
                            </Space>
                          </Row>
                        )
                      }
                  </Space>
              </Card>
              <Button type="link" style={{ marginTop: 16 }}><Link to="/settings">Go back to settings</Link></Button>

            </motion.div>
          </Col>
        </Row>
\      </>
    </div>
  );
}
