import axios from "axios";

const carbonApiURL =
  process.env.REACT_APP_API_BASE_URL ||
  "https://us-central1-research-studio-f176e.cloudfunctions.net/api";

export const tokenFetcher = async ( userId ) => {
  try {
    const response = await axios.get(carbonApiURL + "/api/carbonToken", {
      params: { userId },
    });
    return response.data; // Must return data containing access_token
  } catch (error) {
    throw error;
  }
};
export const enableCarbonFeatures = async (user) => {
  const userId = user.uid;
  const token = await tokenFetcher( userId );

  try{
    const response = await axios.post(
      "https://api.carbon.ai/modify_user_configuration",
      {
        "configuration_key_name": "sparse_vectors",
        "value": {
          "enabled": true
        }
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token.access_token}`,
          "customer-id": `${userId}`
        }
      }
    )
    return response;
  } catch(error) {
    throw new Error("Failsed to update user features: " + error)
  }
}
export const getCarbonUser = async (user) => {
  const userId = user.uid
  const token = await tokenFetcher( userId );
  try {
    const response = await axios.post(
      "https://api.carbon.ai/user",
      {
        "customer_id": `${userId}`,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token.access_token}`,
          "customer-id": `${userId}`
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch file user: " + error);
  }
};

export const getResearchEmbedding = async (query, k, user, researchId) => {
  const userId = user.uid
  const token = await tokenFetcher( userId );
  try {
    const response = await axios.post(
      "https://api.carbon.ai/embeddings",
      {
        query: query,
        k: k,
        tags: {
          researchId: researchId,
        }
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token.access_token}`,
          "customer-id": `${userId}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch research context: " + error);
  }
};
export const fetchFiles = async ( user, filters ) => {
  const userId = user.uid;
  const token = await tokenFetcher( userId );
  try {
    const response = await axios.post(
      "https://api.carbon.ai/user_files_v2",
      {
        include_parsed_text_file: true,
        filters: filters
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token.access_token}`,
          "customer-id": `${userId}`,
        },
      }
    );
    return response.data.results;
  } catch (error) {
    throw new Error("Failed to research fetch files:" + error);
  }
};

const fetchResearchFiles = async ( user, researchId ) => {
  const userId = user.uid;
  const token = await tokenFetcher( userId );
  try {
    const response = await axios.post(
      "https://api.carbon.ai/user_files_v2",
      {
        include_parsed_text_file: true,
        filters: {
          tags: {
            researchId: researchId,
          },
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token.access_token}`,
          "customer-id": `${userId}`,
        },
      }
    );
    return response.data.results;
  } catch (error) {
    throw new Error("Failed to research fetch files:" + error);
  }
};
const fetchUserFiles = async ( user ) =>{
    const userId = user.uid;
    const token = await tokenFetcher( userId );
    try{
        const response = await axios.post(
            "https://api.carbon.ai/user_files_v2",{},
            {
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Token ${token.access_token}`,
                  "customer-id": `${userId}`,
                },
            }
        )
        return response.data.results;
    } catch ( error ) {
        throw new Error("Failed to fetch files:" + error);
    }
}
export const deleteResearchFiles = async ( user, researchId ) => {
  let fileIds = [];
  const userId = user.uid;
  const token = await tokenFetcher( userId);
  try {
    const getFileIds = await fetchResearchFiles( user, researchId );
    getFileIds.forEach((file) => {
      fileIds.push(file.id);
    });
    axios.post(
      "https://api.carbon.ai/delete_files",
      {
        file_ids: fileIds,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token.access_token}`,
          "customer-id": `${userId}`,
        },
      }
    );
  } catch (error) {
    throw new Error("Failed to delete files: " + error);
  }
};
export const deleteResearchFile = async ( user, fileId ) => {
  const userId = user.uid;
  const token = await tokenFetcher( userId);
  const fileIdArray = [fileId]
  try {

    axios.post(
      "https://api.carbon.ai/delete_files",
      {
        file_ids: fileIdArray,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token.access_token}`,
          "customer-id": `${userId}`,
        },
      }
    );
  } catch (error) {
    throw new Error("Failed to delete files: " + error);
  }
};
export const deleteUserFiles = async ( user ) => {
  let fileIds = [];
  const userId = user.uid;
  const token = await tokenFetcher( userId );
  const filesArray = await fetchUserFiles( user );
  try {
    if(filesArray.length > 0){
      filesArray.forEach((file) => {
        fileIds.push(file.id);
      });
      axios.post(
        "https://api.carbon.ai/delete_files",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "authorization": `Token ${token.access_token}`,
            "customer-id": `${userId}`,
          },
        }
      );
    }
  } catch (error) {
        throw new Error("Failed to delete files: " + error);
    }
}

export const uploadTextToResearch =  async ( userId, researchId, researchContext, fileName ) => {
    const uploadEndpoint = "https://api.carbon.ai/upload_text";
    const token = await tokenFetcher( userId );
    try{
        await axios.post(
            uploadEndpoint,
            {
                contents: researchContext,
                name: fileName,
                chunk_size: 600,
                chunk_overlap: 100,
                tags: {
                    researchId: researchId
                }
            },
            {
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Token ${token.access_token}`,
                  "customer-id": `${userId}`,
                },
            }
        )
    } catch (error){
        throw error;
    }
}
export const updateFileTags = async ( user, researchId, tags ) => {
  let fileIds = [];
  const userId = user.uid;
  const token = await tokenFetcher( userId );
  try {
    const getFileIds = await fetchFiles( 
      user,
      { name: researchId}
    );
    getFileIds.forEach((file) => {
      fileIds.push(file.id);
    });
    axios.post(
      "https://api.carbon.ai/create_user_file_tags",
      {
        organization_user_file_id: fileIds[0], 
        tags: tags
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${token.access_token}`,
          "customer-id": `${userId}`,
        },
      },
      
    );
  } catch (error) {
    throw new Error("Failed to delete files: " + error);
  }
};
export const viewResearchFiles = async ( user, researchId ) => {
  let files = [];
  try {
    const response = await fetchResearchFiles( user, researchId );
    response.forEach((file) => {
        const fileId = file.id;
        const fileName = file.name;
        const syncStatus = file.sync_status;
        const fileParsedTextURL = file.parsed_text_url;
        const fileErrorMessage = file.sync_error_message;
        files.push({ fileId, fileName, fileParsedTextURL, syncStatus, fileErrorMessage } );
    });

    return files;
  } catch (error) {
    throw new Error("Failed to get files: " + error);
  }
};
