import React, { useState, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import { Row, Tooltip, ColorPicker, Button, Space, Input, message, Modal, Col, Card, theme, Divider, Switch, ConfigProvider, Tag, Badge } from 'antd';
import { HomeOutlined, InfoCircleFilled, WarningFilled, EditOutlined } from "@ant-design/icons";
import { db, dbRef, set, onValue, get } from "../../firebase_setup/firebase";
import { AppBar, PremiumCheckout } from "../../components";
import { DEFAULT_BRANDING } from "../../utils/consts";
import { motion } from "framer-motion";
import { NotionIcon } from "../../utils";
import Cookies from "js-cookie";
import styles from "./Settings.module.css";

const { useToken } = theme;

function BrandingCard( {userData, user} ) {
  const { token } = useToken();
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [brandingModalOpen, setBrandingModalOpen] = useState(false);
  const [confModalOpen, setConfModalOpen] = useState(false);
  const [brandingValues, setBrandingValues] = useState(null);
  const [updatedBrandingValues, setUpdatedBrandingValues] = useState(null);
  const [companyNameValue, setCompanyNameValue] = useState();
  const [primaryColorValue, setPrimaryColorValue] = useState();
  const [primaryBgValue, setPrimaryBgValue] = useState();
  const [backgroundValue, setBackgroundValue] = useState();
  const [foregroundValue, setForegroundValue] = useState();
  const [borderValue, setBorderValue] = useState();
  const [defaultBranding, setDefaultBranding] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const addHexHash = ( value ) => {
    if(value){
      return `#${value}`
    }
  }
  const Square = ( color, border ) => {
    return <div style={{ backgroundColor: color, width: "24px", height: "24px", borderRadius: "4px", border: border ? `1px solid ${border}`: '' }}></div>
  }
  const areBrandingValuesEqual = (values1, values2) => {
    if(
      values1.companyName === values2.companyName &&
      values1.primaryColor === values2.primaryColor &&
      values1.primaryBg === values2.primaryBg &&
      values1.foregroundColor === values2.foregroundColor &&
      values1.backgroundColor === values2.backgroundColor &&
      values1.borderColor === values2.borderColor
    ){
      return true
    } else {
      return false;
    }
  };

  const showBrandingModal = () => {
    if(userData && userData.subscriptionStatus === "Premium"){
      setBrandingModalOpen(true);
      resetBrandingFields();
      if(!brandingValues){
        saveBrandingToDb(DEFAULT_BRANDING);
      }
    }
    else {
      setCheckoutModalOpen(true);
    }
  }
 
  const updateBranding = ( e, variable ) => {
    setSaveDisabled(false);
    if(variable === "companyName"){
      setCompanyNameValue(e.target.value);
    } else if(variable === "primaryColor"){
      setPrimaryColorValue(e.toHex())
    } else if(variable === "primaryBg"){
      setPrimaryBgValue(e.toHex())
    } else if(variable === "background"){
      setBackgroundValue(e.toHex());
    } else if(variable === "foreground"){
      setForegroundValue(e.toHex());
    } else if(variable === "border"){
      setBorderValue(e.toHex());
    }
  }
  const getBrandingData = async (uid) => {
    const userRef = dbRef(db, `/users/${uid}/branding`);
    onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setBrandingValues(data);
        if(areBrandingValuesEqual(data, DEFAULT_BRANDING)){
          setDefaultBranding(true);
        } else{
          setDefaultBranding(false)
        }
        return data;
    });
  };
  const saveBrandingToDb = async (values, successMessage) => {
    try{
      const brandingRef = dbRef(db, `users/${user.uid}/branding`);
        set(brandingRef, values);
        successMessage && message.success(successMessage)
    } catch(error){
      message.error("Branding could not be saved")
    }
  }

  const saveBranding = async () => {
    const values =  {
      companyName: companyNameValue ? companyNameValue : DEFAULT_BRANDING.companyName,
      primaryColor: primaryColorValue ? primaryColorValue : DEFAULT_BRANDING.primaryColor,
      primaryBg: primaryBgValue ? primaryBgValue : DEFAULT_BRANDING.primaryBg,
      backgroundColor: backgroundValue ? backgroundValue : DEFAULT_BRANDING.backgroundColor,
      foregroundColor: foregroundValue ? foregroundValue : DEFAULT_BRANDING.foregroundColor,
      borderColor: borderValue ? borderValue : DEFAULT_BRANDING.borderColor,
    }
    await saveBrandingToDb(values, "Branding saved successfully");
    if(areBrandingValuesEqual(values, DEFAULT_BRANDING)){
      setDefaultBranding(true);
    } else {
      setDefaultBranding(false);
    }
    setBrandingModalOpen(false)
  }
  const resetBranding = () =>{
    setUpdatedBrandingValues(null);
    resetBrandingFields();
    setDefaultBranding(true)
    saveBrandingToDb(DEFAULT_BRANDING, "Branding updated")
  }
  const resetBrandingFields = () => {
    setCompanyNameValue(brandingValues.companyName);
    setPrimaryColorValue(brandingValues.primaryColor);
    setPrimaryBgValue(brandingValues.primaryBg);
    setBackgroundValue(brandingValues.backgroundColor);
    setForegroundValue(brandingValues.foregroundColor);
    setBorderValue(brandingValues.borderColor);
  }
  const discardChanges = () => {
    setUpdatedBrandingValues(null);
    resetBrandingFields();
    setConfModalOpen(false);
    setBrandingModalOpen(false);
  }
  const closeBrandingModal = () => {
    if(updatedBrandingValues){
        setConfModalOpen(true);
        setUpdatedBrandingValues(null);
    }
    else {
      setConfModalOpen(false);
      setBrandingModalOpen(false);
      resetBrandingFields();
    }
  }
  const closeConfModal = () => {
    setConfModalOpen(false);
  }
  const handleCheckoutModalClose = () => {
    setCheckoutModalOpen(false);
  }
  useEffect(() => {
    if(userData && !userData.branding){
      getBrandingData(user.uid);
    } else {
      setBrandingValues(userData.branding);
    }
  })
  return(
    <Row gutter={16} justify="center">
          <Col xxl={8} xl={10} lg={12} md={16} xs={24}>
            <motion.div initial={{ opacity: 0, scale: 0.7 }} whileInView={{ opacity: 1, scale:1 }}>
              <Card
                title={
                  <Space size="small">
                    Report Branding
                    { defaultBranding ? <Tag color="blue">Default</Tag> : <Tag color="orange">Custom</Tag> }
                  </Space>
                }
                extra={
                  <Button onClick={() => showBrandingModal()} type="link">
                    <EditOutlined /> Customize
                  </Button>
                }
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <HomeOutlined style={{ fontSize: "24px" }} />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <b>Company Name:</b>
                    {brandingValues && brandingValues.companyName && brandingValues.companyName !== "" ? brandingValues.companyName : "-"}
                  </div>
                </div>
                <Divider />
                <p className={styles.infoRow}>
                  {Square(
                    brandingValues
                      ? addHexHash(brandingValues.primaryColor)
                      : addHexHash(DEFAULT_BRANDING.primaryColor)
                  )}
                  <b>
                    <Space size="small">
                      Primary Color
                      <Tooltip
                        title={
                          <p>
                            Used for:
                            <br />
                            - Titles
                            <br />
                            - Cover slide background
                            <br />
                            - Ending slide background
                            <br />
                          </p>
                        }
                      >
                        <InfoCircleFilled style={{ color: token.colorPrimary }} />
                      </Tooltip>
                    </Space>
                  </b>
                </p>
                <Divider />
                <p className={styles.infoRow}>
                  {Square(
                    brandingValues
                      ? addHexHash(brandingValues.primaryBg)
                      : addHexHash(DEFAULT_BRANDING.primaryBg)
                  )}
                  <b>
                    <Space size="small">
                      Primary Foreground
                      <Tooltip
                        title={
                          <p>
                            Used for:
                            <br />- Text color over primary color
                          </p>
                        }
                      >
                        <InfoCircleFilled style={{ color: token.colorPrimary }} />
                      </Tooltip>
                    </Space>
                  </b>
                </p>
                <Divider />
                <p className={styles.infoRow}>
                  {Square(
                    brandingValues
                      ? addHexHash(brandingValues.foregroundColor)
                      : addHexHash(DEFAULT_BRANDING.foregroundColor)
                  )}
                  <b>
                    <Space size="small">
                      Foreground Color
                      <Tooltip
                        title={
                          <p>
                            Used for:
                            <br />
                            - Body text <br />- Table text
                          </p>
                        }
                      >
                        <InfoCircleFilled style={{ color: token.colorPrimary }} />
                      </Tooltip>
                    </Space>
                  </b>
                </p>
                <Divider />
                <p className={styles.infoRow}>
                  {Square(
                    brandingValues
                      ? addHexHash(brandingValues.backgroundColor)
                      : DEFAULT_BRANDING.backgroundColor,
                    brandingValues
                      ? addHexHash(brandingValues.borderColor)
                      : addHexHash(DEFAULT_BRANDING.borderColor)
                  )}
                  <b>
                    <Space size="small">
                      Background Color
                      <Tooltip
                        title={
                          <p>
                            Used for:
                            <br />- Page background
                          </p>
                        }
                      >
                        <InfoCircleFilled style={{ color: token.colorPrimary }} />
                      </Tooltip>
                    </Space>
                  </b>
                </p>
                <Divider />
                <p className={styles.infoRow}>
                  {Square(
                    brandingValues
                    ? addHexHash(brandingValues.borderColor)
                    : addHexHash(DEFAULT_BRANDING.borderColor)
                    )}
                  <b>
                    <Space size="small">
                      Border Color
                      <Tooltip
                        title={
                          <p>
                            Used for:
                            <br />
                            - Separators <br />- Table borders
                          </p>
                        }
                      >
                        <InfoCircleFilled style={{ color: token.colorPrimary }} />
                      </Tooltip>
                    </Space>
                  </b>
                </p>
                {
                  defaultBranding === false && 
                  <>
                    <Divider/>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="link" danger onClick={resetBranding}>Reset to Default</Button>
                  </div>
                  </>
                } 
              </Card>
            </motion.div>
          </Col>
          <Modal
            title="Customize Branding"
            open={brandingModalOpen}
            onCancel={closeBrandingModal}
            footer={
              <Space>
                <Button onClick={closeBrandingModal}>Close</Button>
                <Button disabled={saveDisabled} type="primary" onClick={saveBranding}>
                  Save Changes
                </Button>
              </Space>
            }
          >
            <Space
              direction="vertical"
              size="middle"
              style={{ width: "100%", marginTop: "16px" }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <p>Company name</p>
                <Input
                  onChange={(e) => updateBranding(e, "companyName")}
                  placeholder="e.g. Abc Inc."
                  defaultValue={
                    brandingValues && (brandingValues.companyName ? brandingValues.companyName : "")
                  }
                  value={companyNameValue}
                />
              </Space>
              <Space direction="vertical" style={{ width: "100%" }}>
                <p>Primary color</p>
                <ColorPicker
                  format="hex"
                  onChange={(e) => updateBranding(e, "primaryColor")}
                  defaultValue={
                    brandingValues &&
                    addHexHash(brandingValues.primaryColor)
                  }
                  showText
                  value={addHexHash(primaryColorValue)}
                />
              </Space>
              <Space direction="vertical" style={{ width: "100%" }}>
                <p>Primary foreground</p>
                <ColorPicker
                  format="hex"
                  onChange={(e) => updateBranding(e, "primaryBg")}
                  defaultValue={
                    brandingValues &&
                    addHexHash(brandingValues.primaryBg)
                  }
                  showText
                  value={addHexHash(primaryBgValue)}
                />
              </Space>
              <Space direction="vertical" style={{ width: "100%" }}>
                <p>Foreground color</p>
                <ColorPicker
                  format="hex"
                  onChange={(e) => updateBranding(e, "foreground")}
                  defaultValue={
                    brandingValues &&
                    addHexHash(brandingValues.foregroundColor)
                  }
                  showText
                  value={addHexHash(foregroundValue)}
                />
              </Space>
              <Space direction="vertical" style={{ width: "100%" }}>
                <p>Background color</p>
                <ColorPicker
                  format="hex"
                  onChange={(e) => updateBranding(e, "background")}
                  defaultValue={
                    brandingValues &&
                    addHexHash(brandingValues.backgroundColor)
                  }
                  showText
                  value={addHexHash(backgroundValue)}
                />
              </Space>
              <Space direction="vertical" style={{ width: "100%" }}>
                <p>Border color</p>
                <ColorPicker
                  format="hex"
                  onChange={(e) => updateBranding(e, "border")}
                  defaultValue={
                    brandingValues &&
                    addHexHash(brandingValues.borderColor)
                  }
                  showText
                  value={addHexHash(borderValue)}
                />
              </Space>
            </Space>
          </Modal>
          <Modal
            title="Discard Changes?"
            width={320}
            centered
            open={confModalOpen}
            icon={<WarningFilled />}
            onCancel={closeConfModal}
            footer={
              <Space>
                <Button onClick={closeConfModal}>Cancel</Button>
                <Button danger type="primary" onClick={discardChanges}>
                  Discard changes
                </Button>
              </Space>
            }
          >
            Are you sure you want to close? You will lose your changes.
          </Modal>
          <PremiumCheckout modalOpen={checkoutModalOpen} modalCloseMethod={handleCheckoutModalClose}/>
        </Row>
  );
}

export function Settings() {

  const { token } = useToken();
  const { user, themeChange, userTheme, userData } = UserAuth();

  const [darkTheme, setDarkTheme] = useState(false);
  const [notionConnected, setNotionConnected] = useState(false);
  const location = useLocation();
  
  const isChildRoute = location.pathname.includes('/settings/notion-integration');
  

  const handleThemeChange = (e) => {
    e.preventDefault();
    if(userTheme === "dark"){
      themeChange('default');
      setDarkTheme(true);
      Cookies.set("userTheme", "default");
    } else {
      themeChange('dark')
      setDarkTheme(false);
      Cookies.set("userTheme", "dark");
    }
  }
 

  useEffect(() => {
    if(userTheme === "dark"){
      setDarkTheme(true);
    } else {
      setDarkTheme(false);
    }
  }, [userTheme]);
  
  const handleNotionIntegration = () => {
    
      window.open("https://api.notion.com/v1/oauth/authorize?client_id=d9eb3f2c-25eb-4488-b967-bd8b9db641b2&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapp.researchstudio.ai%2Fsettings%2Fnotion-integration", "_self")
    
  }
  useEffect(() => {
    if(userData && (userData.notion_db_id || userData.notion_page_id)){
      setNotionConnected(true);
    } else{
      setNotionConnected(false);
    }
  }, [userData]);
 
  return (
    <div
      className={styles.profileContainer}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        flexGrow: 1,
      }}
    >
      {
        isChildRoute ? <Outlet /> : (
          <>
          <AppBar
        title="Settings"
        verticalCentering
      />
        <Row gutter={16} justify="center">
          <Col xxl={8} xl={10} lg={12} md={16} xs={24}>
            <motion.div layout initial={{ opacity: 0, scale: 0.7 }} whileInView={{ opacity: 1, scale:1 }}>
              <Card title="App Settings">
                <label
                  onClick={(e) => handleThemeChange(e)}
                  className={styles.infoRow}
                >
                  <b>Theme: </b>
                  <Space size="small">
                    <ConfigProvider
                      theme={{
                        token: {
                          colorTextQuaternary: token.colorPrimary,
                          colorTextTertiary: token.colorPrimaryHover,
                        },
                      }}
                    >
                      <Switch
                        checked={darkTheme}
                        checkedChildren="Dark"
                        unCheckedChildren="Light"
                      />
                    </ConfigProvider>
                  </Space>
                </label>
              </Card>
            </motion.div>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col xxl={8} xl={10} lg={12} md={16} xs={24}>
            <motion.div layout initial={{ opacity: 0, scale: 0.7 }} whileInView={{ opacity: 1, scale:1 }}>
              <Card title="Export Integrations">
                <div
                  className={styles.infoRow}
                >
                  <Space>
                    <img src={NotionIcon} alt="Notion" style={{ width: "24px" }} />
                    <Space style={{ gap: 0 }} direction='vertical'>
                      <p><b>Notion Export</b></p>
                      {
                        notionConnected ? (
                          <Badge status="success" text="Notion connected" />
                        ) : (
                          <Badge status="error" text="Notion not connected" />)
                      }
                    </Space>
                  </Space>
                  <Space style={{marginLeft: "auto"}} size="small" direction='vertical'>
                   {
                     !notionConnected && <Button type="default" onClick={handleNotionIntegration}>Connect</Button>
                   }
                  </Space>
                </div>
              </Card>
            </motion.div>
          </Col>
        </Row>
        <BrandingCard user={user} userData={userData} />
    </>
        )
      }
      </div>
      
  );
}
