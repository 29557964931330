import { createContext, useState, useContext } from 'react';

const CheckoutContext = createContext();

export const CheckoutContextProvider = ({ children }) => {
    const [checkoutInProgress, setCheckoutInProgress] = useState(false);
    const [checkoutJustCompleted, setCheckoutJustCompleted] = useState(false);

    return (
        <CheckoutContext.Provider value={{ checkoutInProgress, setCheckoutInProgress, checkoutJustCompleted, setCheckoutJustCompleted }}>
            {children}
        </CheckoutContext.Provider>
    )
}

export const CheckoutDetails = () =>{
    return useContext(CheckoutContext);
}