import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CheckoutDetails } from "../../context/CheckoutContext";
import { Result, Button, Row } from "antd";
import { Link } from "react-router-dom"

export function SubscriptionFeedbackComplete() {
    const { checkoutJustCompleted, setCheckoutJustCompleted } = CheckoutDetails();
    const navigate = useNavigate();

    const handleResetState = () => {
        setCheckoutJustCompleted(false);
    }
    useEffect(() => {
        if (!checkoutJustCompleted) {
            navigate("/");
        }
    }, []);
    return (
        <Row justify="center" align="middle" style={{ width: "100%", height: "100%" }}>
            <Result
                status="success"
                title="Successfully Purchased Premium Subscription!"
                subTitle="You are now all set. Your subscription will renew every month on the same date unless you cancel it."
                extra={[
                    <Link to="/" onClick={handleResetState} >
                        <Button onClick={handleResetState} type="primary">Go Home</Button>
                    </Link>
                ]}
            />
        </Row>
    )
}
export function SubscriptionFeedbackIncomplete() {
    const { checkoutJustCompleted, setCheckoutJustCompleted } = CheckoutDetails();
    const navigate = useNavigate();

    const handleResetState = () => {
        setCheckoutJustCompleted(false);
    }
    useEffect(() => {
        if (!checkoutJustCompleted) {
            navigate("/");
        }
    }, []);
    return (
        <Row justify="center" align="middle" style={{ width: "100%", height: "100%" }}>
            <Result
                status="warning"
                title="Subscription Purchase was Unsuccessfull!"
                subTitle="The purchase was either interrupted or an error has occured, please go to your account and try again."
                extra={[
                    <Link to="/account">
                        <Button onClick={handleResetState} type="primary">Go to My Account</Button>
                    </Link>
                ]}
            />
        </Row>
    )
}


