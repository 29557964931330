import React, { useState, useEffect } from 'react'
import { message, Button, Typography, theme, Modal, Segmented, Row, Tag, Col, Card } from "antd";
import getStripe from "../../utils/getStripe";
import { api } from "../../utils/api";
import { UserAuth } from '../../context/AuthContext';
import { CheckoutDetails } from '../../context/CheckoutContext';

const { useToken } = theme;

export function PremiumCheckout({modalOpen, modalCloseMethod }) {
    const { Text, Title } = Typography;
    const { user, userData } = UserAuth();
    const { token } = useToken();
    const { setCheckoutJustCompleted } = CheckoutDetails();
    const [price, setPrice] = useState("annual");
    const [loadingStripe, setLoadingStripe] = useState(false);
    const [customerEmail, setCustomerEmail] = useState(null);
    
    const handleCheckout = async ( priceId ) => {
        setCheckoutJustCompleted(true);
        setLoadingStripe(true);
        if (customerEmail && user.uid) {
          const customerData = {
            email: customerEmail,
            uid: user.uid,
          };
          let customerId;
          if (userData.customerId) {
            customerId = userData.customerId;
          } else {
            // If customerId is not in Firebase, create a new one.
            try {
              const customerResponse = await api.post(
                "/create_customer",
                customerData
              );
              customerId = customerResponse.data.customerId;
              // Update the user data state to reflect the new customerId.
            } catch (error) {
              message.error(error);
            }
          }

          // At this point, we know that customerId is valid either from Firebase or from a new Stripe Customer.
          // We then initiate checkout.
          const stripe = await getStripe();
          // Call your server to create a checkout session
          try{

              const response = await api.post("/create-checkout-session", {
                customerId,
                uid: user.uid,
                priceId: priceId,
              });
              const { sessionId } = response.data;
              const { error } = await stripe.redirectToCheckout({ sessionId });
              if (error) {
                message.warning(error.message);
              } else {
                setCheckoutJustCompleted(true);
              }
          } catch(error){
              message.error("Checkout failed: " + error);
              setLoadingStripe(false);
          }

          setLoadingStripe(false);
        }
    };
    
    const prices = [
      {
        label: "Annual",
        value: "annual",
      },
      {
        label: "Monthly",
        value: "monthly",
      }
    ]
 
    useEffect(() => {
        if (user) {
            setCustomerEmail(user.email);
        }
    }, [user, user.email]);
    useEffect(() =>{
        if(!modalOpen){
            setLoadingStripe(false);
        }
    }, [modalOpen])
    return (
            <Modal 
                open={modalOpen}
                footer={null}
                onCancel={modalCloseMethod}>
                  <Row justify="center">
                    <Segmented 
                      options={prices}
                      onChange={(value) => setPrice(value)}/>
                  </Row>
                  { price === "annual" && (
                    <Row gutter={16} style={{ textAlign: "center", marginBottom: "16px", marginTop: 24 }}>
                      <Col span={12}>
                        <Card>
                          <div style={{ display: "flex", gap: 4, justifyContent: "center", alignItems: "end"}}>
                            <Title style={{ marginBottom: "8px",  color: token.colorPrimary, lineHeight: 1 }}  level={1}>$20</Title>
                            <Text style={{ lineHeight: 3 }}> / Month</Text>
                          </div>
                          <Row justify="center">
                            <Tag style={{ marginBottom: 16 }}>Individual (Freelancer)</Tag >
                          </Row>
                          <div>
                            <Text><strong>20% discount</strong></Text>
                          </div>
                          <div>
                            <Text>Up to 14 days of refund period</Text>
                          </div>
                          <Row justify="center" style={{ margin: "42px 0px 0px" }}>
                            <Button size="large"  loading={ loadingStripe } onClick={() => handleCheckout("price_1PNYNzJaE3zIYCxx1IefqbeZ")} type="primary">Upgrade Now</Button>
                          </Row>
                          </Card>
                      </Col>
                      <Col span={12}>
                        <Card style={{ backgroundColor: token.colorBgLayout }}>
                          <div style={{ display: "flex", gap: 4, justifyContent: "center", alignItems: "end"}}>
                            <Title style={{ marginBottom: "8px",  color: token.colorPrimary, lineHeight: 1 }}  level={1}>$24</Title>
                            <Text style={{ lineHeight: 3 }}> / User / Month</Text>
                          </div>
                          <Row justify="center">
                            <Tag color="purple" style={{ marginBottom: 16 }}>Business</Tag >
                          </Row>
                          <div>
                            <Text><strong>20% discount</strong></Text>
                          </div>
                          <div>
                            <Text>Up to 14 days of refund period</Text>
                          </div>
                          <Row justify="center" style={{ margin: "42px 0px 0px" }}>
                            <Button size="large"  type='link' href="mailto:contact@researchstudio.ai?subject=Business Plan Enquiry">Contact Us</Button>
                          </Row>
                        </Card>
                      </Col>
                    </Row> )}
                  { price === "monthly" && (
                    <Row gutter={16} style={{ textAlign: "center", marginBottom: "16px", marginTop: 24 }}>
                      <Col span={12}>
                        <Card>
                          <div style={{ display: "flex", gap: 4, justifyContent: "center", alignItems: "end"}}>
                            <Title style={{ marginBottom: "8px",  color: token.colorPrimary, lineHeight: 1 }}  level={1}>$25</Title>
                            <Text style={{ lineHeight: 3 }}> / Month</Text>
                          </div>
                          <Row justify="center">
                            <Tag style={{ marginBottom: 16 }}>Individual (Freelancer)</Tag >
                          </Row>
                          <div>
                            <Text>Full flexibility</Text>
                          </div>
                          <div>
                            <Text>Up to 7 days of refund period</Text>
                          </div>
                          <Row justify="center" style={{ margin: "42px 0px 0px" }}>
                            <Button size="large"  loading={ loadingStripe } onClick={() => handleCheckout("price_1PNYLNJaE3zIYCxxLoITG4rh")} type="primary">Upgrade Now</Button>
                          </Row>
                          </Card>
                      </Col>
                      <Col span={12}>
                        <Card style={{ backgroundColor: token.colorBgLayout }}>
                          <div style={{ display: "flex", gap: 4, justifyContent: "center", alignItems: "end"}}>
                            <Title style={{ marginBottom: "8px",  color: token.colorPrimary, lineHeight: 1 }}  level={1}>$30</Title>
                            <Text style={{ lineHeight: 3 }}> / User / Month</Text>
                          </div>
                          <Row justify="center">
                            <Tag color="purple" style={{ marginBottom: 16 }}>Business</Tag >
                          </Row>
                          <div>
                            <Text>Full flexibility</Text>
                          </div>
                          <div>
                            <Text>Up to 14 days of refund period</Text>
                          </div>
                          <Row justify="center" style={{ margin: "42px 0px 0px" }}>
                            <Button size="large"  type='link' href="mailto:contact@researchstudio.ai?subject=Business Plan Enquiry">Contact Us</Button>
                          </Row>
                        </Card>
                      </Col>
                    </Row> )}
            </Modal>
    )
}
