import React, { useState } from "react"
import { useNavigate, Link } from "react-router-dom";
import { Row, Space, Button, Typography, theme, Avatar, Divider, Tooltip } from "antd";
import { ArrowLeftOutlined, CloseOutlined, FullscreenOutlined, FullscreenExitOutlined, SettingOutlined, CaretUpOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import styles from "./AppBar.module.css";

const { useToken } = theme

export function AppBar({ backButton, title, extraButton, panel, toggledOffText, toggledOnText, description, cta, avatarSrc, backDestination, backActionTitle, avatar, action, verticalCentering, fullWidthContent, overlay, tag }) {
    const [fullscreen, setFullscreen] = useState(false);
    const [panelToggle, setPanelToggle] = useState(true);
    const { token } = useToken()
    const { Title, Text } = Typography;
    const navigate = useNavigate();

    const togglePanel = () => {
        setPanelToggle(!panelToggle);
    }
    const enterFullScreen = () => {
        if(fullscreen === false){
            document.documentElement.requestFullscreen();
            setFullscreen(true)
        } else {
            document.exitFullscreen();
            setFullscreen(false);
        }
    }
    const goBackFromPresentation = () =>{
        navigate(backDestination);
        if(fullscreen){
            document.exitFullscreen();
        }
    }
    return (
    !overlay ? (
        <Row style={{ backgroundColor: token.colorBgLayout}} className={styles.appBar} align="middle">
                
                <Space direction="vertical" space="small" className={styles.responsiveSpace} style={{ width: "100%", gridTemplateColumns: fullWidthContent ? "1fr": "auto 1fr", rowGap: "2px"  }}>
                    { 
                        backButton && 
                        <Button type="link" className={styles.appBarBackAction} onClick={() => navigate(backDestination)} style={{ padding: 0 }}> 
                            <Space direction="horizontal" space="small">
                                <ArrowLeftOutlined />
                                <span className={styles.backActionTitle}>{ backActionTitle }</span>
                            </Space>
                        </Button>
                    }
                    <div className={styles.appBarContent} style={{ alignItems: verticalCentering ? "center": "start"}}>
                    
                        { avatar && <span className={styles.appBarAvatar}>{ avatar }</span>  }
                        { avatarSrc && <Avatar size={56} src={avatarSrc} style={{ marginRight: '16px' }} />}
                    
                        <div className={styles.appBarText}>
                            <Title ellipsis className={styles.appBarTitle}>{ title }</Title>
                            { tag && tag }
                            { description && <Text ellipsis={{ tooltip: description }} className={styles.appBarDescription} type="secondary">{ description }</Text>}
                        </div>
                        
                        <span className={styles.appBarActions}>
                                { action && <span className={styles.appBarAction }>{ action }</span>} 
                                { cta && <span className={styles.appBarCta}>{ cta }</span> }
                        </span>
                    </div>
                    {
                        panel ? (
                        <>
                            <Row style={{ width: "100%" }} justify="space-between">{ panelToggle ? toggledOnText : toggledOffText} <Button type="link" onClick={togglePanel}><CaretUpOutlined style={{ transition: "ease .2s", transform: panelToggle && "rotate(180deg)"}} /> {panelToggle ? " Hide Widget Tray" : " Show Widget Tray"}</Button></Row>
                            {
                            panelToggle && (
                            <motion.div
                                style={{ width: "100%" }}
                                initial={{ opacity: 0, scaleX: 1 }}
                                animate={{ opacity: 1, scaleX: 1 }}
                                exit={{ opacity: 0, scaleX: 0 }}>
                                   
                                { panel }
                            </motion.div>
                            )}
                        </>
                        ) : null
                    }
                </Space>
            </Row> 
        ) : (
    <Row className={styles.appBarOverlay} align="middle">
        <Space direction="vertical" space="small" className={styles.responsiveSpace} style={{ width: "100%" }}>
           
            <div className={styles.appBarContent} style={{ alignItems: verticalCentering ? "center": "start"}}>
                { avatar && <span className={styles.appBarAvatar}>{ avatar }</span>  }
                <div className={styles.appBarText}>
                    <Title level={3} ellipsis style={{ color: "#ffffff" }} className={styles.appBarTitle}>{ title }</Title>
                    { description && <Text ellipsis={{ tooltip: description }} className={styles.appBarDescription} type="secondary">{ description }</Text>}
                </div>
                
                <span className={styles.appBarActions}>
                    { action && <span className={styles.appBarAction } >{ action }</span>} 
                    { cta && <span className={styles.appBarCta}>{ cta }</span> }
                </span>
                { 
                    backButton && 
                    <Space direction="horzontal" space="small">
                        <Link to="/settings"><Button type="link" style={{ color:"#9460ff" }} ><SettingOutlined /> Customize Presentation</Button></Link>
                        {extraButton}
                    <Tooltip title={ fullscreen ? "Exit full screen" : "Enter full screen"}>
                        <a style={{ color: "#FFFFFF", fontSize: "20px", paddingRight: "12px" }} className={styles.appBarBackAction} onClick={enterFullScreen} > 
                            { fullscreen ? <FullscreenExitOutlined/> : <FullscreenOutlined /> }
                        </a>
                    </Tooltip>
                    <Tooltip title="Exit presentation">
                        <a style={{ color: "#FFFFFF", fontSize: "20px" }} className={styles.appBarBackAction} onClick={goBackFromPresentation} > 
                            <CloseOutlined />
                        </a>
                    </Tooltip>
                    </Space>
                }
            </div>
           
        </Space>
    </Row> ));
}
