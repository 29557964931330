import { api } from "./api"
import { getResearchEmbedding } from "./carbonUtils";
import { 
  AI_SUMMARY_CHUNKS,
  AI_REPLY_CHUNKS, 
  AI_INSIGHTS_CHUNKS, 
  AI_STATEMENTS_CHUNKS  } from "./consts";
  
  const parseResponseToJson = (responseString) => {
    try {
      // Only replace escaped newlines and backslashes that are not part of a control sequence
      const cleanedString = responseString
        .replace(/\\n/g, '') // Remove escaped newlines
        .replace(/\\(?![bfnrt\\"\/])/g, ''); // Remove backslashes that are not part of escape sequences
  
      // Attempt to parse the cleaned string as JSON
      const parsedJson = JSON.parse(cleanedString);
  
      return parsedJson;
    } catch (error) {
      throw new Error("Failed to parse string to JSON: " + error.message);
    }
  }

// NEW FUNCTIONS - depreacting langchain
export const rsSummary = async ( user, researchId, model ) => {
  try {
    let contextDocs = [];
    const embeddings = 
    await getResearchEmbedding ( 
      'Go through the context and look up user statements and info. Summarisation of context which includes user and or people insights, persons descriptions, takeaways and potential solutions',
      AI_SUMMARY_CHUNKS,
      user, 
      researchId
    );
    contextDocs = embeddings.data.documents;
    const response = contextDocs.length > 0 && await api.post('/api/rsSummary', { contextDocs, user, model });
    return response.data.data;
  } catch (error) {
    throw new Error("Failed to generate summary: " + error);
  }
};
export const rsInsights = async ( description, user, researchId, model ) => {
  try {
    let contextDocs = [];
    const embeddings = 
    await getResearchEmbedding ( 
      'Extract all possible users/people/persons/interviewees insights, takeaways, pain points, feedback, features, struggles, solutions, suggestions based on the following tags: User Needs, Pain Points, Usability Issues, Feature Requests, Behavioural Trends, Accessibility Concerns, Navigation & Flow, Content Clarity, Technical Issues, Conversion Barriers, Competitive Insights, Demographic Insights, Demographic Insights, Device/Platform, Context of Use, Feedback on Visuals, Security & Privacy, Onboarding Experience, Retention Factors', 
      5,
      user, 
      researchId
    );
    contextDocs = embeddings.data.documents;
    const response = contextDocs.length > 0 && await api.post('/api/rsInsights', { description, contextDocs, user, model });
    const jsonResponse = response && parseResponseToJson(response.data.data);
    return jsonResponse;
  } catch (error) {
    throw new Error("Failed to extract insights: " + error);
  }
};
export const rsStatements = async ( user, researchId, model ) => {
  try {
    let contextDocs = [];
    const embeddings = 
    await getResearchEmbedding ( 
      'Extract all possible users/people/persons/interviewees insights, takeaways, pain points, feedback, features, struggles, solutions, suggestions based on the following tags: User Needs, Pain Points, Usability Issues, Feature Requests, Behavioural Trends, Accessibility Concerns, Navigation & Flow, Content Clarity, Technical Issues, Conversion Barriers, Competitive Insights, Demographic Insights, Demographic Insights, Device/Platform, Context of Use, Feedback on Visuals, Security & Privacy, Onboarding Experience, Retention Factors', 
      5,
      user, 
      researchId
    );
    contextDocs = embeddings.data.documents;
    const response = await api.post('/api/rsStatements', { contextDocs, user, model });
    const jsonResponse = parseResponseToJson(response.data.data);
    return jsonResponse;
  } catch (error) {
    throw new Error("Failed to extract and analyze statements: " + error);
  }
};
export const rsSentiment = async ( user, text, researchId, model ) => {
  try {
    let contextDocs = [];
    const embeddings = 
    await getResearchEmbedding ( 
      'Extract all possible users/people/persons/interviewees data related to their sentiment or emootions. This includes feedback, ideas, pains, struggles and all other forms of possible ideas that users talk about with sentiment and', 
      1,
      user, 
      researchId
    );
    contextDocs = embeddings.data.documents;
    const response = await api.post('/api/rsSentiment', { contextDocs, text, user, model });
    const jsonResponse = response.data.data;
    return jsonResponse;
  } catch (error) {
    throw new Error("Failed to extract and analyze statements: " + error);
  }
};
export const rsReply = async ( question, user, researchId, externalChat, model ) => {
  try {
    let contextDocs = [];
    const embeddings =
      await getResearchEmbedding(
        question, 
        4,
        user, 
        researchId
      );
    contextDocs = embeddings.data.documents;
    const response = await api.post('/api/rsAsk', { question, contextDocs, user, externalChat, model });
    return response.data.data;
  } catch (error) {
    throw new Error("Failed to reply: " + error);
  }
};
export const rsPersona = async ( user, researchId, model ) => {
  try {
    let contextDocs = [];
    const embeddings =
      await getResearchEmbedding(
        "Gather all the user or people or persons insights, takeaways, pain points, feedback, features and suggestions and generate a persona. Check if they introduced themselves, if they have a name.",
        5,
        user, 
        researchId
      );
    contextDocs = embeddings.data.documents;
    const response = await api.post('/api/rsPersona', { contextDocs, user, model});
    const jsonResponse = parseResponseToJson(response.data.data);
    return jsonResponse;
  } catch (error) {
    throw new Error("Failed to generate persona: " + error);
  }
}
export const rsUserJourney = async ( user, researchId, summary, insights, model ) => {
  try {
    let contextDocs = [];
    const embeddings = 
      await getResearchEmbedding(
        "Go through the context and find relevant information, insights, descriptions, solutions, pain points etc. to help you create a user journey map.",
        4,
        user, 
        researchId
      );
    contextDocs = embeddings.data.documents;
    const response = await api.post('/api/rsUserJourney', { contextDocs, user, summary, insights, model });
    const jsonResponse = parseResponseToJson(response.data.data);
    return jsonResponse;

  } catch (error) {
    throw new Error("Failed to generate user journey map: " + error);
  }
}
export const rsCompetitors = async ( user, researchId, summary, model ) => {
  try {
    let contextDocs = [];
    const embeddings = 
      await getResearchEmbedding (
        'Find and extrat possible competiors or any information that might guide us towards finding such competitors in the given text', 
        2, 
        user, 
        researchId
    );
    contextDocs = embeddings.data.documents;
    const response = await api.post('/api/rsCompetitors', { contextDocs, user, summary, model });
    const jsonResponse = parseResponseToJson(response.data.data);
    return jsonResponse;

  } catch (error) {
    throw new Error("Failed to lookup competitors: " + error);
  }
}
export const rsQuestions = async ( user, researchId, model ) => {
  try {
    let contextDocs = [];
    const embeddings = 
      await getResearchEmbedding (
        'Based on the given context, act as a ux researcher and extract all questions to analyze them. The questions need to be calsified based on text, category and type (leading, yes/no, open ended etc).',
        5, 
        user, 
        researchId
      );
    contextDocs = embeddings.data.documents;
    const response = await api.post('/api/rsQuestions', { contextDocs, user, model });
    const jsonResponse = parseResponseToJson(response.data.data);
    return jsonResponse;

  } catch (error) {
    throw new Error("Failed to extract questions");
  }
}
export const rsJtbd = async ( user, researchId, model ) => {
  try {
    let contextDocs = [];
    const embeddings = 
      await getResearchEmbedding (
        'Based on the given context, act as a ux researcher and extract all user/people feedback, goals, motivations, descriptions and so on to create Jobs to Be Done.',
        5, 
        user, 
        researchId
      );
    contextDocs = embeddings.data.documents;
    const response = await api.post('/api/rsJtbd', { contextDocs, user, model });
    const jsonResponse = parseResponseToJson(response.data.data);
    return jsonResponse;
  } catch (error) {
    throw new Error("Failed to extract questions");
  }
}
export const rsUserStories = async ( user, researchId, model ) => {
  try {
    let contextDocs = [];
    const embeddings = 
      await getResearchEmbedding (
        'Based on the given context, act as a ux researcher and extract all user/people feedback, goals, motivations, descriptions and so on to create User Stories.',
        5, 
        user, 
        researchId
      );
    contextDocs = embeddings.data.documents;
    const response = await api.post('/api/rsUserStories', { contextDocs, user, model });
    const jsonResponse = parseResponseToJson(response.data.data);
    return jsonResponse;
  } catch (error) {
    throw new Error("Failed to extract questions");
  }
}