import React from 'react'
import { Row, Result, Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export function NotFound() {
    const navigate = useNavigate();
    const goHome = () => {
        navigate("/");
    }
    return (
        <Row style={{height: "100%"}} justify="center" align="middle">
            <Result 
                title="404"
                status="404"
                subTitle="The page you accessed could not be found"
                extra={ <Button type="primary" onClick={goHome}><HomeOutlined/> Go Home</Button> }
            />
        </Row> 
    )
}
