import React, { useEffect } from "react";
import { ConfigProvider, Tag, theme, Select, Card, Input, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { useToken } = theme;
export const StickyNote = React.forwardRef (({
  index,
  text,
  onClick,
  onAdd,
  onEdit,
  actions,
  onCancelEdit,
  isEditing,
  isButton,
  tag,
  tagColor,
  onSaveEdit,
  onSaveOnEnter,
  onCancelOnEsc,
  textAreaOnEnter,
  selectOnChange,
  selectOptions,
  defaultTag,
  selectPlaceholder
}, ref) => {
  const { token } = useToken();
  const selectStyles = {
    width: "160px",
    position: "absolute",
    zIndex: 3,
    left: "8px",
    top: "8px",
    textAlign: "left"
  }
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // prevent a form submission
        onSaveOnEnter && onSaveOnEnter();
      } else if (e.key === 'Escape') {
        onCancelOnEsc && onCancelOnEsc();
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    // remove event listeners when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [onSaveOnEnter, onCancelOnEsc]);
  if (isEditing) {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: token.colorWarningBg,
            colorText: token.colorWarningTextActive,
            colorBorderSecondary: token.colorWarningBorder,
          },
        }}
      >
        <Card
          className={"stickyNote " + (isButton && "stickyButton")}
          style={{ position: "relative" }}
          actions={[
            <Button
              type="text"
              onClick={isButton ? onAdd : () => onSaveEdit(index)}
            >
              <CheckOutlined />
            </Button>,
            <Button type="text" onClick={() => onCancelEdit(index)}>
              <CloseOutlined />
            </Button>,
          ]}
        >
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: token.colorBgContainer,
                    colorText: token.colorText,
            },}}>
                {
                  selectOptions &&
                  <Select
                    placeholder={selectPlaceholder}
                    defaultValue={defaultTag}
                    style={selectStyles}
                    onChange={(value) => !isButton ? selectOnChange(index, value): selectOnChange(value)}
                    options={
                      selectOptions.map((option, i) => {
                        return { label: option, value: option }
                      })}
                  />
                }
                <Input.TextArea
                    autoFocus
                    defaultValue={text}
                    ref={ref}
                    onPressEnter={textAreaOnEnter}
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      position: "absolute",
                      top: "0px",
                      paddingTop: "42px",
                      left: "0px",
                      right: "0px",
                      bottom: "58px",
                    }}
                    onChange={(e) =>
                    !isButton
                        && onEdit(index, e.target.value)
                    }
                />
            </ConfigProvider>
        </Card>
      </ConfigProvider>
    );
  } else {
  /*else if(status === "positive"){
    return(
        <ConfigProvider
                    theme={{
                        token: {
                            colorBgContainer: token.colorSuccessBg,
                            colorText: token.colorSuccessTextActive,
                            colorBorderSecondary: token.colorSuccessBorder
                        }
                    }}>
                        <Card>
                            <p>{text}</p>
                        </Card>
        </ConfigProvider>
        )
    }
    else if(status === "warning"){
        return(
            <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: token.colorWarningBg,
                    colorText: token.colorWarningTextActive,
                    colorBorderSecondary: token.colorWarningBorder
                }
            }}>
                <Card>
                    <p>{text}</p>
                </Card>
            </ConfigProvider>
        )
    }
    else if(status === "negative"){
        return(
            <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: token.colorErrorBg,
                    colorText: token.colorErrorTextActive,
                    colorBorderSecondary: token.colorErrorBorder
                }
            }}>
                <Card>
                    <p>{text}</p>
                </Card>
            </ConfigProvider>
        )
    } */
    return (
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: isButton
              ? token.colorWarningBg
              : token.colorWarningBgHover,
            colorText: isButton ? token.colorWarningBorder : token.colorText,
            colorBorderSecondary: isButton && token.colorWarningBorder,
          },
        }}
      >
        <Card
          actions={actions}
          className={"stickyNote " + (isButton && "stickyButton")}
          onClick={onClick}
          title={tag && <Tag color={tagColor}>{tag}</Tag>}
        >
          <div
            style={{
              backgroundColor: isButton
                ? token.colorWarningBgHover
                : token.colorWarningBorder,
            }}
            className="stickyNoteFold"
          ></div>
          {isButton ? <p>+ Add Insight</p> : <p>{text}</p>}
        </Card>
      </ConfigProvider>
    );
  }
});
