import React from "react";

import {
  DragOutlined, MessageFilled,
} from "@ant-design/icons";
import {
  theme,
  Button,
  Popover,
  Space,
} from "antd";


import styles from "./ResearchPage.module.css";
import { useDroppable, useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

const { useToken } = theme;


export const WidgetsPanel = ({ onClose, open, openRequestForm, onChatOpen, widgets }) => {
    const { setNodeRef } = useDroppable({
      id: "widgets-panel"
    });
    const contentStyle = {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    }
    const { token } = useToken();
    return (
        <div className={styles.widgetsContainer}>
            <div ref={setNodeRef} className={styles.widgetsPanel}>
                <div style={contentStyle}>
                    <div className={styles.widgetPanelScrollview}>
                        { widgets }
                    </div>
                    <div style={{border: widgets.length < 1 && "none", color: token.colorText }} className={styles.requestWidget}>
                        { widgets.length < 1 ? "You used all widgets available, need another?" : "Can't find the widget you need?" }
                        <Button type="link" onClick={ openRequestForm }>+ Request Widget</Button>
                    </div>
                </div> 
            </div>
            <Button type="primary" onClick={onChatOpen} style={{ height: "100%", padding:"24px" }}>
                <Space direction="vertical" align="center">
                <MessageFilled style={{ fontSize: "32px" }} />
                AI Assitant
                </Space>
            </Button>
        </div>
    )
  }
export const DraggableWidget = ({ id, text, icon, iconSrc, coverSrc, disablePopover, description, isDragging }) => {
    const {attributes, listeners, setNodeRef, transform} = useDraggable({
      id: id
    });
    const { token } = useToken();
    const style = {
      transform: CSS.Translate.toString(transform),
      userSelect: "none",
      touchAction: "none",
      transition: "transform 0.1s ease",
      flex: "none",
      cursor: isDragging ? "grabbing" : "grab"
    }
    return (
      !disablePopover ? (
          <Popover
            content={
              <div style={{ width: "260px"}}>
                <img src={ coverSrc } alt={`${text} widget cover`} style={{width: "100%", height: "132px", display: "block", borderRadius: 12, marginBottom: "12px" }} />
                <p style={{ color: token.colorText }}>{ description }</p>
              </div>
            }>
            <div className={styles.draggableWidget} ref={setNodeRef} {...attributes} {...listeners}>
              <Space direction="vertical" align="center">
                {icon}
                <img src={iconSrc} style={{ height: "72px" }} alt={`${text} icon`} />
                <p style={{ fontWeight: 500, color: token.colorText }}>{text}</p>
              </Space>
            </div>
          </Popover>
      ) : ( 
        <div className={styles.draggableWidget} style={style} ref={setNodeRef} {...attributes} {...listeners}>
          <Space direction="vertical" align="center">
            {icon}
            <img src={iconSrc} style={{ height: "72px" }} alt={`${text} icon`} />
            <p style={{ fontWeight: 500, color: token.colorText }}>{text}</p>
          </Space>
        </div>
      )
    );
  }
export const DroppableArea = ({ id, text, visible, isOver }) => {
    const { token } = useToken();
    const { setNodeRef } = useDroppable({
      id: id
    });
    return (
      <div ref={setNodeRef} style={{ display: visible ? "flex" : "none", borderColor: isOver && token.colorPrimary, backgroundColor: isOver && "rgba(210, 180, 255, 0.6)" }} className={styles.draggingArea}>
        <DragOutlined style={{ fontSize: "64px", color: token.colorPrimary, transition: "ease-in .2s", transform: isOver && "rotate(90deg)"}} />
        { text }
      </div>
    );
}