import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  message,
  theme,
  Card,
  Button,
  Tag,
  Typography,
  Empty,
  Spin,
  Tooltip
} from "antd";
import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import { rsUserJourney } from '../../utils';
import { Loader } from '../../components';
import { dbRef, db, set } from "../../firebase_setup/firebase";

const { useToken } = theme;

export const CustomerJourneyWidget = ({ summary, presentation, researchId, researchData, editor, onRemove, user }) => {
    const [customerJourney, setCustomerJourney] = useState();
    const [loading, setLoading] = useState(false);
    
    const { Title } = Typography;
    const { token } = useToken();
  
    const saveCustomerJourneyToDb = async ( text ) => {
      const cJourneyRef = dbRef(db, `research_list/${researchId}/c_journey`);
      try{
        await set(cJourneyRef, text);
      } catch (error){
        message.error(`Error saving process diagram: ${error}`)
      }
    }
    const handleCustomerJourney = async () => {
      const insightsText = researchData.takeaways.map(insight => {
        return insight.insight;
      })
      const combinedInsights = insightsText.join('\n');
      setLoading(true);
      try {
        if(researchData){
          const response = await rsUserJourney(user, researchId, summary, combinedInsights, researchData.model);
          setCustomerJourney(response);
          saveCustomerJourneyToDb(response);
        }
      } catch (error) {
        message.error("Failed to generate user journey map " + error);
      }
      setLoading(false);
    }
    useEffect(()=>{
        if(researchData.c_journey){
            setLoading(false);
        }
    }, [researchData]);
    useEffect(() => {
      if(researchData && researchData.c_journey){
        setCustomerJourney(researchData.c_journey);
      } else{
        handleCustomerJourney();
      }
    }, [researchData])
    return(
      <Row gutter={16}> 
          <Col span={24}>
              { !presentation &&  
                <Row justify="space-between" align="middle" style={{ marginTop: "16px", marginBottom: "8px"}}>
                    <Title style={{ margin: 0}} level={3}>
                      { editor && ( 
                        loading ? (
                          <Spin style={{ marginRight: 12 }} />) : (
                          <Tooltip title="Re-generate user journey map">
                            <Button type="link" icon={<SyncOutlined />} onClick={handleCustomerJourney}/> 
                          </Tooltip>)
                        )}
                      User Journey
                    </Title> 
                  { editor && 
                    <Button type="link" danger onClick={onRemove}><DeleteOutlined /></Button> }
                </Row>
           }
           <Row>
            <div 
              style={{width: "100%", overflowX: !presentation && "auto", gap: "8px",  display: "flex", flexWrap: presentation && "wrap"}}>
              { 
                customerJourney ? (
                customerJourney.map( (stage, index) => {
                  return (
                  <Card 
                    style={{ minWidth: !presentation && "300px", width: presentation && "calc(25% - 6px)" }} 
                    title={stage.stage} 
                    extra={ <Tag color={token.colorPrimary}>{ index+1 }</Tag> }>
                    {stage.text}
                  </Card> )})) : (
                    
                    <Card style={{width: "100%", display: "flex", justifyContent: "center"}}>
                      {
                        loading ? 
                        ( <Loader title="Generating User Journey Map" size="small" loading={loading} /> ) : (
                          <Empty description="User journey couldn't be generated">
                            { editor && <Button type="primary" onClick={handleCustomerJourney}><SyncOutlined /> Try Again</Button>}
                          </Empty> )
                      }
                    </Card>
                  )
                }
            </div>
          </Row>     
          </Col> 
      </Row>
    );
}