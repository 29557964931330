import React, { useState, useEffect } from "react";
import { DeleteOutlined, UserOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Space,
  Col,
  Avatar,
  Row,
  Card,
  message,
  Empty,
  Button,
  Typography,
  Tooltip,
  Spin,
} from "antd";
import { db, dbRef, set } from "../../firebase_setup/firebase";
import { rsPersona } from "../../utils";
import styles from "../WidgetStyles.module.css";
import { Loader } from "../../components";

export const PersonaWidget = ({
  researchId,
  user,
  summary,
  researchData,
  editor,
  presentation,
  onRemove,
}) => {
  const [personasInfo, setPersonasInfo] = useState();
  const [loading, setLoading] = useState(false);

  const { Text, Title } = Typography;

  const savePersonaToDb = async (persona) => {
    try {
      const personaRef = dbRef(db, `research_list/${researchId}/user_persona`);
      await set(personaRef, persona);
    } catch (error) {
      message.error(`Error saving persona: ${error}`);
    }
  };

  async function handlePersona() {
    setLoading(true);
    try {
      const response = await rsPersona(user, researchId, researchData.model);
      setPersonasInfo(response);
      await savePersonaToDb(response);
    } catch (error) {
      throw new Error("Failed to generate persona" + error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (researchData && researchData.persona) {
      setPersonasInfo(researchData.persona);
      setLoading(false);
    } else if (researchData && researchData.user_persona) {
      setPersonasInfo(researchData.user_persona);
      setLoading(false);
    } else if (researchData && researchData.summary) {
      handlePersona();
    }
  }, [researchData]);
  return (
    <Row gutter={16}>
      <Col span={24}>
        {!presentation && (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginTop: "16px", marginBottom: "8px" }}
          >
            <Title style={{ margin: 0 }} level={3}>
              {editor &&
                (loading ? (
                  <Spin style={{ marginRight: 12 }} />
                ) : (
                  <Tooltip title="Re-generate persona">
                    <Button
                      type="link"
                      icon={<SyncOutlined />}
                      onClick={handlePersona}
                    />
                  </Tooltip>
                ))}
              User Persona
            </Title>
            {editor && (
              <Button type="link" danger onClick={onRemove}>
                <DeleteOutlined />
              </Button>
            )}
          </Row>
        )}
      </Col>
      <Col span={24}>
        <Card className={styles.personaCard}>
          {personasInfo ? (
            <Row gutter={24}>
              <Col xl={7} lg={8} md={8} xs={24}>
                <Space style={{ padding: "8px" }} align="start" size="small">
                  <Avatar size={42} icon={<UserOutlined />} />

                  <div>
                    {personasInfo.name && (
                      <Title style={{ margin: "0" }} level={1}>
                        {personasInfo.name}
                      </Title>
                    )}
                    {personasInfo.occupation && (
                      <Text style={{ margin: "0" }} type="secondary">
                        {personasInfo.occupation}
                      </Text>
                    )}
                    <div style={{ marginTop: "16px" }}>
                      {personasInfo.age && (
                        <div>
                          <Text>Age: {personasInfo.age}</Text>
                        </div>
                      )}
                      {personasInfo.location && (
                        <div>
                          <Text>Location: {personasInfo.location}</Text>
                        </div>
                      )}
                    </div>
                  </div>
                </Space>
              </Col>

              <Col
                xl={17}
                lg={16}
                md={16}
                xs={24}
                style={{ paddingTop: "24px" }}
              >
                  <>
                    {personasInfo.background && (
                      <div>
                        <Title level={3}>Background</Title>
                        <Text>{personasInfo.background}</Text>
                      </div>
                    )}

                    <Row gutter={24}>
                      {personasInfo.goals && (
                        <div className={styles.personaInfo}>
                          <Title level={3}>Goals</Title>
                          <ul>
                            {personasInfo.goals.map((goal, index) => (
                              <li key={index}>{goal}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {personasInfo.pain_points && (
                        <div className={styles.personaInfo}>
                          <Title level={3}>Pain Points</Title>
                          <ul>
                            {personasInfo.pain_points.map((point, index) => (
                              <li key={index}>{point}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {personasInfo.insights && (
                        <div className={styles.personaInfo}>
                          <Title level={3}>Insight</Title>
                          <ul>
                            {personasInfo.insights.map((insight, index) => (
                              <li key={index}>{insight}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </Row>
                  </>
              </Col>
            </Row>
          ) : (
            loading ? (
            <Loader title="Generating User Persona" size="small" loading={loading} /> ) : (
            <Empty description="Persona couldn't be generated">
              {editor && (
                <Button type="primary" onClick={() => handlePersona()}>
                  <SyncOutlined /> Try Again
                </Button>
              )}
            </Empty> )
          )}
        </Card>
      </Col>
    </Row>
  );
};
