import React  from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"; 
import './index.css';
import App from './App';
import { ResearchPage, NotFound, SharePage, ResearchList, Account, Settings, Resources, SubscriptionFeedbackComplete, SubscriptionFeedbackIncomplete, ResearchReport, Transcripts, NotionIntegration } from "./views";
import { AuthContextProvider } from './context/AuthContext';
import { CheckoutContextProvider } from './context/CheckoutContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {
        path: "/",
        element: <ResearchList />
      },
      {
        path: "/account",
        element: <Account />
      },
      {
        path: "/settings",
        element: <Settings />,
        children: [
          {
            path: "notion-integration",
            element: <NotionIntegration />
          }
        ]
      },
      {
        path: "/research/:id",
        element: <ResearchPage />,
      },
      {
        path: "/research/:id/*",
        element: <ResearchPage />
      },
      {
        path: "/resources",
        element: <Resources />
      },
      {
        path: "/share/:shareToken",
        element: <SharePage />
      },
      {
        path: '/complete',
        element: <SubscriptionFeedbackComplete/>
      },
      {
        path: '/incomplete',
        element: <SubscriptionFeedbackIncomplete/>
      },
      /*
      {
        path: '/transcripts',
        element: <Transcripts/>
      }, */
      {
        path: '/*',
        element: <NotFound/>
      }
    ],
  }
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

      <CheckoutContextProvider>
      <AuthContextProvider>
        <RouterProvider router={router} />
      </AuthContextProvider>
      </CheckoutContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals