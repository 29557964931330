import React, { useState, useEffect } from "react";
import {
  DeleteOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Col,
  Tag,
  Tooltip,
  Row,
  Card,
  message,
  Spin,
  Rate,
  Empty,
  Space,
  Button,
  Typography
} from "antd";
import { db, dbRef, set } from "../../firebase_setup/firebase";
import { Loader } from "../../components";
import { rsUserStories } from "../../utils";

export const UserStoriesWidget = ({ researchId, researchData, editor, onRemove, presentation, user }) => {
    const [userStories, setUserStories] = useState();
    const [loading, setLoading] = useState(false);

    const { Title, Text } = Typography
    const saveUserStories = async ( data ) => {
      try{
        const userStoriesRef = dbRef(db, `research_list/${researchId}/user_stories`);
        await set(userStoriesRef, data)
      } catch (error) {
        message.error(`Error saving questions: ${error}`);
      }
    }
     const extracUserStories = async () => {
        try {
            setLoading(true);
            const response = await rsUserStories(user, researchId, researchData.model);
            setUserStories(response);
            saveUserStories( response );
            setLoading(false);
        } catch (error) {
            throw new Error('Failed to analyze questions' + error);
        }
    }
    useEffect(() => {
      if(researchData && researchData.user_stories){
        setUserStories(researchData.user_stories);
      } else if(researchData && !researchData.user_stories){
        extracUserStories();
      }
    }, [researchData])
    return(
      <>
        <Row gutter={16}>
          <Col span={24}>
          { !presentation && 
            <Row justify="space-between" align="middle" style={{ marginTop: "16px", marginBottom: "8px"}}>
              <Title style={{ margin: 0}} level={3}>
              { editor && ( 
                loading ? (
                  <Spin style={{ marginRight: 12 }} />) : (
                  <Tooltip title="Re-analyse questions">
                    <Button type="link" icon={<SyncOutlined />} onClick={extracUserStories}/> 
                  </Tooltip>)
              )}
                User Stories
              </Title> 
              { editor && 
              <Button type="link" danger onClick={onRemove}><DeleteOutlined /></Button> }
            </Row> }
          </Col>
          <Col span={24}>
            <Row gutter={8}>
            {
              userStories ?
              userStories.map((story, i) =>{
                return(
                  <Col style={{ marginBottom: "8px"}} key={i} lg={12} xl={8} xxl={8} xs={24} sm={24}>
                    <Card title={story.title}>
                      <Text>{story.description}</Text>
                    </Card>
                  </Col>)
              }):
                <Card style={{ width: "100%" }}>
                    { loading ? 
                      ( <Loader size="small" title="Generating User Stories..."  loading={loading}/> ) : ( 
                        <Row justify="center">
                          <Empty description="User stories couldn't be generated">
                            { editor && <Button type="primary"  onClick={() => extracUserStories()}><SyncOutlined /> Try Again</Button> }
                          </Empty>
                        </Row> )
                    }
                </Card>
            }
            </Row>
          </Col>
        </Row>
      </>
    );
  }
