import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import {
  Space,
  Col,
  Popconfirm,
  ConfigProvider,
  message,
  theme,
  Row,
  notification,
  Button,
} from "antd";
import { StickyNote, Loader } from "../../components";
import { dbRef, db, set } from "../../firebase_setup/firebase"
import { rsInsights } from '../../utils'

const { useToken } = theme;

export const InsightsWidget = React.memo(({ user, researchData, researchId, editor  }) => {
    const [takeaways, setTakeAways] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTag, setSelectedTag] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [tags, setTags] = useState([]);

    const { token } = useToken();  
    const insightFieldRef = useRef(null);
    const [ notificationApi, contextHolder ] = notification.useNotification();
  
    const openNotifcation = ( message, description, icon ) => {
      notificationApi.open({
          message: message,
          description: description,
          icon: icon,
          duration: 3,
      });
    }
   
    const saveTakewaysToDb = async (response) => {
      try {
        const takeawaysRef = dbRef(db, `research_list/${researchId}/takeaways`);
        await set(takeawaysRef, response);
      } catch(error){
        openNotifcation(
          "Failed to save insights", 
          "Insights could not be saved", 
          <ExclamationCircleTwoTone twoToneColor={token.colorError} />);
      }
    }
    const saveTagsToDb = async (tags) => {
      try {
        const tagsRef = dbRef(db, `research_list/${researchId}/insights_tags`);
        await set(tagsRef, tags);
      } catch(error){
        openNotifcation(
          "Failed to save taggs", 
          "Tags could not be saved", 
          <ExclamationCircleTwoTone twoToneColor={token.colorError} />);
      }
    }
    
    const handleAdd = async () => {
      if(insightFieldRef.current.resizableTextArea.textArea.value){
        const newTakeaways = [{insight: insightFieldRef.current.resizableTextArea.textArea.value, tag: selectedTag}, ...takeaways];
        setTakeAways(newTakeaways);
        await saveTakewaysToDb(newTakeaways);
        message.success("Insight added!");
        setEditIndex(null);
      } else {
        message.warning("Insight cannot be empty");
      }
    }
    const handleEdit = (index) => {
      const newValue = {insight: insightFieldRef.current.resizableTextArea.textArea.value, tag: selectedTag};
      setTakeAways((prevTakeaways) => {
        const updatedTakeaways = [...prevTakeaways];
        updatedTakeaways[index] = newValue;
        return updatedTakeaways;
      });
    };
    const handleTagSelection = (index, value) => {
      setSelectedTag(value);
      setTakeAways((prevTakeaways) => {
        const updatedTakeaways = [...prevTakeaways];
        updatedTakeaways[index].tag = value;
        return updatedTakeaways;
      });
    }
    const handleDelete = async (index) => {
      let updatedTakeaways = [...takeaways];
      updatedTakeaways.splice(index, 1);
      setTakeAways(updatedTakeaways);
      await saveTakewaysToDb(updatedTakeaways);
      message.success("Insight deleted!")
    }
    
    const handleSaveEdit = async (index) => {
      setEditIndex(null);
      // Save the edited insight to Firebase
      await saveTakewaysToDb(takeaways);
      message.success("Insight saved!")
    }
    
    const handleCancelEdit = () => {
      setEditIndex(null);
    }
    const handleInsights = async() => {
      setLoading(true);
      try{
        if(researchData){
          const response = await rsInsights(researchData.description, user, researchId, researchData.model ? researchData.model: "041");
          const uniqueTags = [...new Set(response.map(item => item.tag))];
          await saveTakewaysToDb(response);
          setTakeAways(response);
          setTags(uniqueTags);
          saveTagsToDb(uniqueTags);
        }
      } catch (error){
        openNotifcation(
          "Insights failed", 
          "Insights could not be generated",
          <ExclamationCircleTwoTone twoToneColor={token.colorError} /> 
          );
        }
      setLoading(false);    
    }
    const colorAllocator = () => {
      const colorMap = {};
      let lastAllocatedColor = 0;
      const colors = ["#fa541c", "#faad14" ,"#52c41a" ,"#13c2c2" ,"#1677ff","#722ed1","#eb2f96"]; // Add more colors as needed
    
      return (tag) => {
        if (!colorMap[tag]) {
          colorMap[tag] = colors[lastAllocatedColor % colors.length];
          lastAllocatedColor++;
        }
        return colorMap[tag];
      };
    };
    
    const getColorForTag = colorAllocator();
    
    useEffect(() => {
      if(researchData && researchData.takeaways) {
        setTakeAways(researchData.takeaways);
        if(researchData.insights_tags){
          setTags(researchData.insights_tags);
        } else {
          const uniqueTags = [...new Set(researchData.takeaways.map(item => item.tag))];
          setTags(uniqueTags);
          saveTagsToDb(uniqueTags);
        }
      } else {
        handleInsights();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [researchData]);
    useEffect(() => {
      if(takeaways.length){
        setLoading(false);
      }
    }, [takeaways]);
    return (
      <Space
          direction="vertical"
          style={{ width: "100%" }}
        >
          {contextHolder}
          {
            loading ?
              <Loader title="Getting Insights" size="small" loading={ loading } />:
              <Row gutter={[16, 16]} wrap={true} align="stretch">
                {
                  editor && (
                    <Col xxl={4} xl={6} lg={8} md={12} xs={24}>
                    <StickyNote 
                      isButton 
                      ref={insightFieldRef}
                      isEditing={editIndex === -1} 
                      onClick={() => setEditIndex(-1)}
                      onCancelEdit={handleCancelEdit}
                      selectOptions={tags}
                      selectOnChange={setSelectedTag}
                      onAdd={handleAdd}
                      selectPlaceholder="Select tag"
                      textAreaOnEnter={handleAdd}
                      onCancelOnEsc={handleCancelEdit}
                    />
                  </Col>)
                }
                { takeaways.map((takeaway, i) => {
                  return(
                    <Col key={i} xxl={4} xl={6} lg={8} md={12} xs={24}>
                      <StickyNote 
                        key={i}
                        index={i}
                        ref={insightFieldRef}
                        tag={takeaway.tag && takeaway.tag}
                        tagColor={getColorForTag(takeaway.tag)}
                        text={takeaway.insight ? takeaway.insight : takeaway}
                        isEditing={i === editIndex}
                        onEdit={handleEdit}
                        defaultTag={takeaway.tag && takeaway.tag}
                        onSaveEdit={handleSaveEdit}
                        selectOnChange={handleTagSelection}
                        selectOptions={tags}
                        onCancelEdit={handleCancelEdit}
                        textAreaOnEnter={handleSaveEdit}
                        onCancelOnEsc={handleCancelEdit}
                        actions={ editor && [
                          <Button key={1} type="text" onClick={() => setEditIndex(i)}><EditOutlined /></Button>,
                          <ConfigProvider
                                theme={{
                                  token: {
                                    colorBgContainer: token.colorBgContainer,
                                    colorText: token.colorText,
                                  },
                                }}
                              >
                            <Popconfirm
                              title="Are you sure you want to delete this insight?"
                              onConfirm={() => handleDelete(i)}
                              okText="Yes"
                              cancelText="No"
                            >
                            <Button key={2} type="text" danger><DeleteOutlined /></Button>
                            </Popconfirm>
                          </ConfigProvider>
                        ]}
                      />
                    </Col>
                  )
                })}
             </Row> 
            }
        </Space>
    )
  });