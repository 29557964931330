import pptxgen from "pptxgenjs";

function chunk(arr, len) {
    let chunks = [];
    let i = 0;
    let n = arr.length;

    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }

    return chunks;
}
const pptxExport = ( researchData, sentimentCounts, brandingValues ) => {
  
    let pptx = new pptxgen();
    const titleStyles = {y: 0.5, x: 0.5, w: 12.5, h: 0.75, fontSize: 38, color: brandingValues &&  brandingValues.primaryColor};
    const bodyStyles =  {y: 1.5, w: 12.5, x: 0.5, h: 5, valign: "top", fontSize:18, color: brandingValues &&  brandingValues.foregroundColor};

    const sentimentColor = (sentiment) => {
      let sentimentColor
      let lowerSentiment = sentiment.toLowerCase();
      if (lowerSentiment.includes('positive')) {
        sentimentColor = '219653';
      } else if (lowerSentiment.includes('negative')) {
        sentimentColor = 'EB5757';
      } else if (lowerSentiment.includes('mixed')) {
        sentimentColor = '5E28D1';
      } else if (lowerSentiment.includes('neutral')) {
        sentimentColor = 'F2994A';
      }
      return sentimentColor;
    }
    
    const sentimentBackground = (sentiment) => {
      let sentimentBackground;
      let lowerSentiment = sentiment.toLowerCase();
      if (lowerSentiment.includes('positive')) {
        sentimentBackground = 'D4EFDF';
      } else if (lowerSentiment.includes('negative')) {
        sentimentBackground = 'F8C9CB';
      } else if (lowerSentiment.includes('mixed')) {
        sentimentBackground = 'EAE0FF';
      } else if (lowerSentiment.includes('neutral')) {
        sentimentBackground = 'FEF3B5';
      }
      return sentimentBackground;
    }
    
    const sentimentEmoji = (sentiment) => {
      let sentimentEmoji;
      let lowerSentiment = sentiment.toLowerCase();
      if (lowerSentiment.includes('positive')) {
        sentimentEmoji = ":)";
      } else if (lowerSentiment.includes('negative')) {
        sentimentEmoji = ":(";
      } else if (lowerSentiment.includes('mixed')) {
        sentimentEmoji = ":|";
      } else if (lowerSentiment.includes('neutral')) {
        sentimentEmoji = ":|";
      }
      return sentimentEmoji;
    }
    const sentimentText = (sentiment) => {
      let sentimentText;
      let lowerSentiment = sentiment.toLowerCase();
      if (lowerSentiment.includes('positive')) {
        sentimentText = "POSITIVE";
      } else if (lowerSentiment.includes('negative')) {
        sentimentText = "NEGATIVE";
      } else if (lowerSentiment.includes('mixed')) {
        sentimentText = "MIXED";
      } else if (lowerSentiment.includes('neutral')) {
        sentimentText = "NEUTRAL";
      }
      return sentimentText;
    }
    pptx.layout = 'LAYOUT_WIDE';
    // Define the master slide
    // Master slide for the title and description
    pptx.defineSlideMaster({
      title: "MASTER_TITLE_SLIDE",
      background: { color: brandingValues &&  brandingValues.primaryColor },
      margin: 1.5,
      objects: [
        { text: { text: "", options: { placeholder: "title" } }},
        { text: { text: "", options: { placeholder: "body", y: 4, w: '100%', x: 0, h: 3  }}},
      ],
    });

    // Master slide for the summary slide
    pptx.defineSlideMaster({
      title: "MASTER_SUMMARY_SLIDE",
      background: { color:  brandingValues &&  brandingValues.backgroundColor },
      margin: 1.5,
      objects: [
        { text: { text: "", options: { placeholder: "title" } }},
        { text: { text: "", options: { placeholder: "body" } }},
      ],
    });

    // Master slide for the list type slides (Insights, Sentiment analysis)
    pptx.defineSlideMaster({
      title: "MASTER_LIST_SLIDE",
      background: { color: brandingValues &&  brandingValues.backgroundColor },
      margin: 1.5,
      objects: [
        { text: { text: "", options: { placeholder: "title" } }},
        { text: { text: "", options: { placeholder: "body" } }},
      ],
    });
    pptx.defineSlideMaster({
      title: "MASTER_CHART_SLIDE",
      background: { color: brandingValues &&  brandingValues.backgroundColor },
      margin: 1.5,
      objects: [
        { text: { text: "", options: { placeholder: "title", ...titleStyles } }},
        { text: { text: "", options: { placeholder: "sentiment_icon", y:2.5, w:1.5, x: researchData.statements ? 1.75: 6, h:1.5 } }},
      ],
    });
    
    let slide;
    let xPosition = 0.5, yPosition = 1.5;
    let yPositionStatement = 1.5;
    let totalHeight = 0;
    let lineHeight = 0.5;
    // Divide the takeaways into blocks of 9 items
    let takeaways = chunk(researchData.takeaways, 9);
    
    // Add a slide using the title master layout
    slide = pptx.addSlide({ masterName: "MASTER_TITLE_SLIDE" });
    slide.addText(researchData.name, { placeholder: "title", y: 3, w: '100%', color: "FFFFFF", fontSize: 48, align: "center" });
    if(researchData.description){
      slide.addText(researchData.description, { placeholder: "body", fontSize: 20, align: "center", y: 4, color: 'FFFFFF' });
    }

    slide = pptx.addSlide({ masterName: "MASTER_SUMMARY_SLIDE" });
    // Extract the first two paragraphs from the summary
    let paragraphs = researchData.summary.split('\n\n');
    let firstTwoParagraphs = paragraphs.slice(0, 2).join('\n\n');
    slide.addText('Summary', { ...titleStyles } );
    slide.addText(firstTwoParagraphs, { placeholder: "body", ...bodyStyles });

    takeaways.forEach((block) => {
      // Add a slide using the list master layout for each block of takeaways
      slide = pptx.addSlide({ masterName: "MASTER_LIST_SLIDE" });
      slide.addText( "Insights", { ...titleStyles })
      // Add the insights in the body
      block.forEach((takeaway, idx) => {
        slide.addText(takeaway, { x: xPosition + 0.25, y: yPosition + 0.1, w: '29%', fontSize: 14, h: 1.5, fill: 'fff1b8' });
        xPosition += 4;
        if((idx + 1) % 3 === 0) {
          xPosition = 0.5;
          yPosition += 1.75;
        }
      });
      // Reset positions
      xPosition = 0.5;
      yPosition = 1.5;
    });
    if(researchData.statements && researchData.statements.length > 0){
      slide = pptx.addSlide({ masterName: "MASTER_CHART_SLIDE" })
      slide.addText( "Sentiment Analysis", {placeholder: "title", ...titleStyles} );
      slide.addText( sentimentEmoji(researchData.sentiment), {placeholder: "sentiment_icon", color: sentimentColor(researchData.sentiment), fontSize:64, rotate: 90,lineHeight: 0.5, fill: sentimentBackground(researchData.sentiment), align: "center", valign:"middle"} );
      slide.addText( "Overall user sentiment is", {x:1, w:3, align: "center", y: 4, h:0.75} );
      slide.addText( sentimentText(researchData.sentiment), {x:1.75, w:1.5, align: "center", y: 4.75, h:0.5, fill: sentimentColor(researchData.sentiment), color:"FFFFFF"} );
      // Add the chart to the slide
      slide.addChart(
        pptx.ChartType.doughnut,
        [
          {
            name: 'Statements',
            labels: ['Negative', 'Neutral', 'Positive', 'Mixed'],
            values: [sentimentCounts.negative, sentimentCounts.neutral, sentimentCounts.positive, sentimentCounts.mixed],
          }
        ],
        {
          chartColors: ['EB5757', 'F2994A', '219653', '5E28D1'],  // Red, Orange, Green, Purple
          x: 5.5,
          y: 1,
          w: 6,
          h: 6,
          dataBorder: { pt: 1, color: 'FFFFFF' },  // Assuming a white border for all segments
          dataLabelPosition: 'ctr', // center the data labels
          dataLabelFontFace: 'Arial', // choose a font
          dataLabelFontSize: 14, // size of the font
          dataLabelColor: 'FFFFFF', // color of the font
          showLegend: true, // Display the legend
          legendPos: 'r', // Place the legend on the right
          showPercent: true,
          legendFontFace: 'Arial', // Choose a font for the legend
          legendFontSize: 14, // Size of the font for the legend
          legendColor: '000000' // Color of the font for the legend
        }
      );
      

      let statements = researchData.statements;
      statements.forEach((statement, index) => {
        // Create a new slide if it's the first statement or totalHeight exceeds 5
        if (index === 0 || totalHeight + lineHeight > 3.5) {
            slide = pptx.addSlide({ masterName: "MASTER_LIST_SLIDE" });
            slide.addText("Sentiment Details", { ...titleStyles });
            // Reset the y position and total height
            yPositionStatement = 1.5;
            totalHeight = 0;
        }
        let text = `• ${statement.statement.trim().replace(/\n/g, '')}`;
        let lines = Math.ceil(text.length / 100);
        let height = lines * lineHeight;
        slide.addText(text, { x: 0.5, y: yPositionStatement, fontSize: 18, w:10, fit: "shrink", h: height, fill: sentimentBackground(statement.sentiment) });
        slide.addText(statement.sentiment, { x: 11, y: yPositionStatement, fontSize: 18, w:2, fill: sentimentColor(statement.sentiment), color: "ffffff", fit: "shrink", h: height });
        
        // Update the y position and total height
        yPositionStatement += height + 0.25;
        totalHeight += height;
      });
    } else {
      slide = pptx.addSlide({ masterName: "MASTER_CHART_SLIDE" })
      slide.addText( "Sentiment Analysis", {placeholder: "title", ...titleStyles, w:13, align: "center"} );
      slide.addText( sentimentEmoji(researchData.sentiment), {placeholder: "sentiment_icon", color: sentimentColor(researchData.sentiment), fontSize:64, rotate: 90,lineHeight: 0.5, fill: sentimentBackground(researchData.sentiment), align: "center", valign:"middle", x:8.5} );
      slide.addText( "Overall user sentiment is", {x:1, align: "center", y: 4, h:0.75, w: 11.5} );
      slide.addText( sentimentText(researchData.sentiment), {x:6, w:1.5, align: "center", y: 4.75, h:0.5, fill: sentimentColor(researchData.sentiment), color:"FFFFFF"} );
      slide.addText( "If you want a more detailed analysis, genereate it in Research Studio by pressing 'View Details' in the Sentiment Widget", {x:1, align: "center", y: 5.5, h:0.75, w: 11.5} );

    }
    const MAX_HEIGHT = 6;
    let currentHeight = 2;  // Starting at 2 since the title and occupation already occupy space

    
    if (researchData.persona) {
      slide = pptx.addSlide({ masterName: "MASTER_LIST_SLIDE" });
      slide.addText("Persona: " + researchData.persona.Name, { ...titleStyles });
      slide.addText("Occupation: " + researchData.persona.Title, { x: 0.5, y: 1, fontSize: 20, w: 12.5, h: 0.5, color: "8C8D8F" });

      const personaFields = [
        { title: "Activity", content: researchData.persona.Activity, fill: "DDEDF4" },
        { title: "Background", content: researchData.persona.Background, fill: "FCEBDB" },
        { title: "Behaviours", content: researchData.persona.Behaviors, fill: "FFF2E8" },
        { title: "Insights", content: researchData.persona.Points, fill: "F0F5FF" },
      ];

      let yPosition = 2.5;

      personaFields.forEach(field => {
        if (field.content) {
          let textHeight = Math.ceil(field.content.length / 100) * 0.5; // rough estimate

          if (currentHeight + textHeight > MAX_HEIGHT) {
            // Add a new slide and reset positions and currentHeight
            slide = pptx.addSlide({ masterName: "MASTER_LIST_SLIDE" });
            slide.addText("Persona: " + researchData.persona.Name + " (cont.)", { ...titleStyles });
            yPosition = 1.5;
            currentHeight = 0;
          }

          slide.addText(`${field.title}: \n ${field.content}`, {
            x: 0.5,
            y: yPosition,
            fontSize: 18,
            w: 12.5,
            fit: "shrink",
            h: textHeight + 1,
            fill: field.fill
          });

          yPosition += textHeight + 1;
          currentHeight += textHeight;
        }
      });
    }
    else{
      slide = pptx.addSlide({ masterName: "MASTER_LIST_SLIDE" });
      slide.addText("No Persona Generated", { ...titleStyles, align: "center" });
      slide.addText("You need to genereate a persona in Research Studio to have it exported here", { x: 0.5, y:1, fontSize: 20, align:"center", w: 12.5, h:0.75, color: "8C8D8F" });
    }
    slide = pptx.addSlide({ masterName: "MASTER_TITLE_SLIDE" });
    slide.addText("Thank you!", { placeholder: "title", y: 3, w: '100%', color: "FFFFFF", fontSize: 64, align: "center" });
    
    pptx.writeFile(`${researchData.name}.pptx`);
  }

  export { pptxExport };