import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  HomeOutlined
} from "@ant-design/icons";
import {
  Button,
  Result,
  Row,
} from "antd";

import {ResearchReport} from "../research-page/ResearchReport";
import { db, onValue, dbRef } from "../../firebase_setup/firebase";


import { UserAuth } from "../../context/AuthContext";
import { Loader } from "../../components";

export function SharePage() {
  const { shareToken } = useParams();
  const { user } = UserAuth();
  const navigate = useNavigate();

  const [researchExists, setResearchExists] = useState(true);
  const [researchData, setResearchData] = useState(null);
  const [researchId, setResearchId] = useState();


  // Determine the file size and file count limits based on the user's subscription status

  async function getResearchData(database, path) {
    const researchRef = dbRef(database, path);

    onValue(researchRef, (snapshot) => {
      let data = snapshot.val();
      let researchData = null;
      for (let key in data) {
        setResearchId(key);
        if (data[key].shareToken === shareToken) {
          researchData = data[key];
          setResearchExists(true);
          break;
        } else {
          setResearchExists(false);
        }
      }
      setResearchData(researchData);
    });
  }
  const goHome = () =>{
    navigate('/');
  }
  useEffect(() => {
    getResearchData(db, `research_list/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, shareToken]);



  useEffect(() => {
    document.body.classList.add("researchPage");
    return () => {
      document.body.classList.remove("researchPage");
    };
  }, []);
  useEffect(() => {
    if(researchData && researchId && researchData.uid === user.uid){
      navigate(`/research/${researchId}/report`);
    }
  },[user, researchData, researchId])

  return (
    researchExists ? (
    !researchData ? (
    <Loader loading={true} fullscreen title="Loading..." />) :(
    <ResearchReport researchData={researchData} researchId={researchId}  user={user} /> )) : (
    <Row style={{height: "100%"}} justify="center" align="middle">
      <Result 
        title="Research Doesn't Exist"
        status="404"
        subTitle="This research was not found on our systems"
        extra={ <Button type="primary" onClick={goHome}><HomeOutlined/> Go Home</Button> }
      />
    </Row>)
  );
}
