import React, { useState, useEffect, useMemo } from "react";
import {
  DeleteOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
  CheckOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  theme,
  Popconfirm,
  Col,
  message,
  Select,
  Empty,
  Tag,
  Row,
  Card,
  Input,
  notification,
  Button,
  Tooltip as AntTooltip,
  Spin,
  Table,
  Typography
} from "antd";
import { dbRef, db, set } from "../../firebase_setup/firebase"
import { rsStatements } from '../../utils'
import { Loader } from '../../components'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const { useToken } = theme;

export const StatementsWidget = ({ researchId, user, editor, researchData, presentation, onRemove }) => {
    const [statements, setStatements] = useState(null);
    const [sentimentCounts, setSentimentCounts] = useState({});
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingRecord, setEditingRecord] = useState({});  // new state to keep track of the editing record
    const [newStatement, setNewStatement] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notificationApi, contextHolder] = notification.useNotification();
    
    const { token } = useToken();
    const { Title } = Typography;
  
    const openNotification = ( message, description, icon ) => {
    notificationApi.open({
            message: message,
            description: description,
            icon: icon,
            duration: 3,
        });
    };
  const handleAdd = () => {
    setNewStatement(true);
    if (editingRecord.statement) {
      message.warning("Please complete the current statement before adding a new one.");
      return;
    }
    const newStatement = {
      statement: '',
      sentiment: 'neutral',
    };
    setEditingRecord(newStatement);
    const newData = [newStatement, ...statements]; // unshift the new statement to the beginning
    setStatements(newData);
    setEditingIndex(0); // Set the editing index to 0
  };
  
  const handleEdit = (index) => {
      setNewStatement(false);
      setEditingIndex(index); // Set the editing index to the index of the record being edited
      setEditingRecord(statements[index])
  };
  
  const handleInputChange = (value, column, key) => {
    setEditingRecord(prev => ({ ...prev, [column]: value }));
  };
  const handleSave = async () => {
    if (!editingRecord.statement) {
      message.warning("Please fill in the statement.");
      return;
    }
    let newData = [...statements];
    if (editingIndex === -1) {
      // Add the record to the top of the array
      newData.unshift({...editingRecord, key: newData.length});
    } else {
      // If it is an existing record, keep it in the same position in the array
      newData[editingIndex] = {...editingRecord};
    }
    try {
      await saveStatementsToDb(newData);
      setStatements(newData);
      setEditingIndex(null);
      setEditingRecord({});
      openNotification(
        "Statement Saved",
        null,
        <CheckCircleTwoTone twoToneColor={token.colorSuccess} />
      );
    } catch (error) {
      openNotification(
        "Failed to Save Statment",
        "Statement could not be saved" + error,
        <ExclamationCircleTwoTone twoToneColor={token.colorError} />
      );    
    }
  };

  const handleCancel = (index) => {
    const newData = [...statements];
    // Check if the statement at this index has been saved before
    if (newStatement) {
      // This is a new statement and hasn't been saved before, so we should remove it
      newData.splice(index, 1);
    }
    setStatements(newData);
    setNewStatement(false);
    setEditingIndex(null);
    setEditingRecord({});
  };
  const handleDelete = async (index) => {
    const newData = [...statements];
    newData.splice(index, 1);
    setStatements(newData);
    await saveStatementsToDb(newData);
    openNotification(
      "Statement Deleted",
      "Statement deleted successfully",
      <CheckCircleTwoTone twoToneColor={token.colorSuccess} />
    )
  };
  const editableColumns = [
    {
      title: "Statement",
      dataIndex: "statement",
      key: "statement",
      width: "100%",
      render: (text, record, index) => {
        const editable = index === editingIndex;
        return editable ? (
        <Input.TextArea
          value={editingRecord.statement}
          autoFocus
          autoSize
          rows={2}
          onChange={(e) =>
            handleInputChange(e.target.value, "statement")
          }
        />
      ) : (
        text
      )
      }
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment",
      key: "sentiment",
      width: "92px",
      render: (text, record, index) => {
        const editable = index === editingIndex;
        return editable ? (
          <Select
            value={editingRecord.sentiment}
            style={{width: "100%"}}
            defaultValue='neutral'
            onChange={(value) => handleInputChange(value, "sentiment")}
            options={[
              { value: 'positive', label: <p style={{ color: token.colorSuccessTextActive}}>Positive</p> },
              { value: 'negative', label: <p style={{ color: token.colorErrorTextActive}}>Negative</p>},
              { value: 'neutral', label: <p style={{ color: token.colorWarningTextActive}}>Neutral</p> },
              { value: 'mixed', label: <p style={{ color: token.colorPrimaryTextActive}}>Mixed</p> },
            ]}
          />
        ) : (
          <Tag color={sentimentColor(text)}>{text}</Tag>
        )
      }
    },
    {
      title: "",
      dataIndex: "operation",
      width: "72px",
      render: (text, record, index) => {
        const editable = index === editingIndex;
        return editable ? (
          <Row wrap={false}>
            <Button
              type="link"
              onClick={handleSave}
            >
              <CheckOutlined />
            </Button>
            <Button onClick={() => handleCancel(index)} type="link"><CloseOutlined /></Button>
          </Row>
        ) : (
          <Row wrap={false}>
            <Button type="link" disabled={editingIndex !== null} onClick={() => handleEdit(index)}>
              <EditOutlined />
            </Button>
            {
              statements.length > 1 ? (
                <Popconfirm 
                  title="Are you sure you want to delete this statement?"
                  onConfirm={() => handleDelete(index)}
                >
                  <Button danger type="link" disabled={editingIndex !== null}>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              ) : ""
            }
          </Row>
        );
      },
    }
  ];
  const readOnlyColumns = [
    {
      title: "Statement",
      dataIndex: "statement",
      key: "statement",
      width: "100%",
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment",
      key: "sentiment",
      width: "92px",
      render: (text) => {
         return <Tag color={sentimentColor(text)}>{text}</Tag>
      }
    },
  ];
  const chartData = useMemo(() => ({
    labels: ['Negative', 'Neutral', 'Positive', 'Mixed'],
    redraw: true,
    datasets: [
      {
        label: 'Statements',
        data: [sentimentCounts.negative, sentimentCounts.neutral, sentimentCounts.positive, sentimentCounts.mixed],
        backgroundColor: [
          token.colorErrorBorder,
          token.colorWarningBorder,
          token.colorSuccessBorder,
          token.colorPrimaryBorder
        ],
        borderColor: [
          token.colorError,
          token.colorWarning,
          token.colorSuccess,
          token.colorPrimary
        ],
        borderWidth: 1,
      },
    ],
  }), [sentimentCounts, token]);
 
  
  const saveStatementsToDb = async( data ) => {
    try {
      const statementsRef = dbRef(db, `research_list/${researchId}/statements`);
      await set(statementsRef, data );
    } catch (error){
        openNotification(
          "Failed to Save Statements", 
          "We couldn't save the statements to the database", 
          <ExclamationCircleTwoTone twoToneColor={token.colorError} />)
    }
  } 
  const countSentiments = ( statementsProp ) => {
    const count = {
      positive: 0,
      negative: 0,
      mixed: 0,
      neutral: 0
    }
    statementsProp.forEach(({ sentiment }) => {
      if(sentiment){
        if (sentiment.toLowerCase() === "positive"){
          count.positive++;
        } else if(sentiment.toLowerCase() === "negative"){
          count.negative++;
        } else if(sentiment.toLowerCase() === "neutral"){
          count.neutral++;
        } else if(sentiment.toLowerCase() === "mixed"){
          count.mixed++;
        }
      }
    })
    setSentimentCounts(count);
  }
  const getStatements = async () => {
    setLoading(true)
      try{
        if(researchData){
          const response = await rsStatements(user, researchId, researchData.model);
          await saveStatementsToDb(response);
          setStatements(response);
        }
      } catch (error){
        openNotification(
          "Statement Extraction Failed",
          "Statements couldn't be generated, either because of an error or lack of context", 
          <ExclamationCircleTwoTone twoToneColor={token.colorError} />);
        }
        setLoading(false)
  }
  
  
  const sentimentColor = (sentiment) => {
    let color;

    if (sentiment && sentiment.toLowerCase().includes("neutral")) {
      color = token.colorWarning;
    } else if (sentiment && sentiment.toLowerCase().includes("negative")) {
      color = token.colorError;
    } else if (sentiment && sentiment.toLowerCase().includes("positive")) {
      color = token.colorSuccess;
    } else if (sentiment && sentiment.toLowerCase().includes("mixed")) {
      color = token.colorPrimary;
    } else {
      color = token.colorFillSecondary;
    }
    return color;
  };
  

  useEffect(() => {
    if(researchData && researchData.statements){
        setStatements(researchData.statements);
    } else {
      getStatements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [researchData]);

  useEffect(() => {
    if (statements) {
      countSentiments(statements);
    }
  }, [statements]);

  return (
    <>
      { !presentation && 
        <Row justify="space-between" align="middle" style={{ marginTop: "16px", marginBottom: "8px"}}>
            <Title style={{ margin: 0}} level={3}>
              {
                loading ? (
                  <Spin style={{ marginRight: 12 }} />) : (
                  <AntTooltip title="Re-analize statements">
                    <Button type="link" icon={<SyncOutlined />} onClick={getStatements}/> 
                  </AntTooltip>)
              }
              Statements Analysis
            </Title> 
          { editor && 
            <Button type="link" danger onClick={onRemove}><DeleteOutlined /></Button> }
        </Row>
      }
      { statements && sentimentCounts ? (        
        <Row gutter={16} >
            <Col style={{ marginBottom: "16px" }} xs={24} sm={12} md={16} lg={16} xl={16}> 
              <Card 
                extra={
                editor && (
                <Button disabled={editingIndex !== null ? true : false} onClick={handleAdd} size="small" type="primary">
                  + Add Statement
                </Button> )}>
                { presentation ? (
                  <Table pagination={{pageSize: 100}} size="middle" columns={readOnlyColumns} dataSource={statements} /> ) : (
                  <Table pagination={{pageSize: 6}} size="middle" columns={editor ? editableColumns : readOnlyColumns} dataSource={statements} /> )}
              </Card>
            </Col>
            <Col style={{ marginBottom: "16px" }} xs={24} sm={12} md={8} lg={8} xl={8}>
                <Card style={{ height: "100%" }} bodyStyle={{ height: "100%"}}>
                    <Row justify="center" style={{ height: "100%", paddingTop:"24px" }}><Doughnut style={{ maxHeight: '380px', maxWidth: "380px", marginBottom: "16px" }} data={chartData} /></Row>
                </Card>
            </Col>
        </Row> ) : (
        loading ? (
          <Card style={{ width: "100%" }}>
            <Row style={{ width: "100%" }} justify="center">
              
                <Loader 
                    loading={loading}
                    size="small"
                    title="Analysing User Statements..." />
            </Row> 
          </Card> ) : (
        <Card>
          <Empty description="Statements couldn't be analyzed">
            {
              editor && 
              <Button
                  type="primary"
                  onClick={() => getStatements()}>
                    <SyncOutlined /> Try Again
              </Button>
            }
          </Empty> 
        </Card> ))
      }
    </>
  );
}