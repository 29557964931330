
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getStorage, ref as storageRef } from 'firebase/storage';
import { getDatabase, query, get, ref as dbRef, set, push, onValue, off, orderByChild, equalTo, remove, update, serverTimestamp } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCw-OBymXNABy4NGNFifOWoFZsHTQEgl7s",
  authDomain: "research-studio-f176e.firebaseapp.com",
  databaseURL: "https://research-studio-f176e-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "research-studio-f176e",
  storageBucket: "research-studio-f176e.appspot.com",
  messagingSenderId: "689233389150",
  appId: "1:689233389150:web:c8df93afe554725900f6cf",
  measurementId: "G-SB59KBNTJ2"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);
const auth = getAuth(firebaseApp);
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6Ldzp8YmAAAAAH89cakxrA8cqsG6XdKLscgGdBmk'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

export {
  firebaseApp,
  db,
  storage,
  storageRef,
  auth,
  dbRef,
  query,
  get,
  set,
  push,
  onValue,
  off,
  orderByChild,
  equalTo,
  update,
  remove,
  serverTimestamp,
  onAuthStateChanged,
};