import React, { useState, useEffect } from 'react'
import styles from "./Loader.module.css";
import { Typography, Row } from "antd";
import { Player } from "@lottiefiles/react-lottie-player";

export function Loader({ loading, title, description, fullscreen, size }) {
    const [animationData, setAnimationData] = useState();
    const [performanceTimeout, setPerformanceTimeout] = useState(false);
    const { Title, Text } = Typography;

    useEffect(() => {
        import('../../loader.json').then(setAnimationData);
        const timeoutId = setTimeout(()=>{
            setPerformanceTimeout(true);
        }, 500)
        return () => {
          clearTimeout(timeoutId);
      };
    }, []);
    return (
      loading === true &&
      (fullscreen ? (
        <Row style={{ height: "100%", width: "100%" }} justify="center" align="middle">
          <div className={styles.loader}>
            <div className={styles.loaderSpinner}>
              {animationData && performanceTimeout && (
                 <Player
                  className={styles.loaderLottie}
                  autoplay
                  loop={true}
                  src={animationData}
                  style={ size === "small" ?{height: "150px", width: "150px" }: { height: "300px", width: "300px" }}
                />
              )}
            </div>
            <div className={styles.loaderText}>
              {title && <Title level={5}>{title}</Title>}
              {description && <Text type="secondary">{description}</Text>}
            </div>
          </div>
        </Row>
      ) : (
        <div className={styles.loader}>
          <div className={styles.loaderSpinner}>
            {animationData && performanceTimeout && (
              <Player
                className={styles.loaderLottie}
                autoplay
                loop={true}
                src={animationData}
                style={ size === "small" ?{height: "150px", width: "150px" }: { height: "300px", width: "300px" }}
              />
            )}
          </div>
          <div className={styles.loaderText}>
            {title && <Title level={5}>{title}</Title>}
            {description && <Text type="secondary">{description}</Text>}
          </div>
        </div>
      ))
    );
}
