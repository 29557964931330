import React from "react";
import {
  Card,
  Row,
  Col,
  Space,
  Tag,
  Typography,
  Button,
} from "antd";
import powerPointTemplate from "../../assets/ux_interview-remote-presentation-template.pptx";
import docTemplate from "../../assets/research_plan-template.docx";
import { DownloadOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { AppBar } from "../../components";

export function Resources() {

    const { Text, Title } = Typography;
   
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "24px", flexGrow: 1 }}>
        <AppBar title="Resources " />
        <Row gutter={16} align="stretch" wrap={true}>
          <Col
            xxl={4}
            xl={6}
            lg={8}
            md={12}
            xs={24}
            style={{ marginBottom: "16px" }}
          >
            <motion.div 
              initial={{ scale: 0.4, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              layout>
              <Card
                bodyStyle={{ textAlign: "center", padding: "24px" }}
                actions={[
                  <Button
                    href={powerPointTemplate}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    type="text"
                  >
                    <DownloadOutlined /> Download
                  </Button>,
                ]}
              >
                <Row justify="center" align="middle" wrap={true}>
                  <Space direction="vertical" size="large">
                    <Space direction="vertical" size="small">
                      <svg
                        width="72"
                        height="72"
                        viewBox="0 0 72 72"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M60.3281 13.2188C62.0156 14.9062 63 17.1562 63 19.5469V63C63 68.0625 58.9219 72 54 72H18C12.9375 72 9 68.0625 9 63V9C9 4.07812 12.9375 0 18 0H43.4531C45.8438 0 48.0938 0.984375 49.7812 2.67188L60.3281 13.2188ZM56.25 63H56.1094V22.5H45C42.4688 22.5 40.5 20.5312 40.5 18V6.89062H18C16.7344 6.89062 15.75 7.875 15.75 9.14062V63C15.75 64.2656 16.7344 65.25 18 65.25H54C55.125 65.25 56.25 64.2656 56.25 63ZM37.125 31.5C42.75 31.5 47.8125 35.7188 48.2344 41.3438C48.7969 47.6719 43.875 52.875 37.6875 52.875H31.5V57.375C31.5 59.3438 29.9531 60.75 28.125 60.75C26.1562 60.75 24.75 59.3438 24.75 57.375V33.75C24.75 32.625 25.7344 31.5 27 31.5H37.125ZM37.6875 46.125C39.7969 46.125 41.625 44.4375 41.625 42.1875C41.625 40.0781 39.7969 38.25 37.6875 38.25H31.5V46.125H37.6875Z"
                          fill="#EB5757"
                        />
                      </svg>
                      <Tag color="red">*.PPTX</Tag>
                    </Space>
                    <Title level={5}>
                      UX Interview - Remote Presentation - Template
                    </Title>
                  </Space>
                </Row>
              </Card>
            </motion.div>
          </Col>
          <Col
            xxl={4}
            xl={6}
            lg={8}
            md={12}
            xs={24}
            style={{ marginBottom: "16px" }}
          >
            <motion.div
              initial={{ scale: 0.4, opacity:0 }}
              whileInView={{ scale: 1, opacity:1 }} 
              layout >
              <Card
                bodyStyle={{ textAlign: "center", padding: "24px" }}
                actions={[
                  <Button
                    href={docTemplate}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    type="text"
                  >
                    <DownloadOutlined /> Download
                  </Button>,
                ]}
              >
                <Row justify="center" align="middle" wrap={true}>
                  <Space direction="vertical" size="large">
                      <Space direction="vertical" size="small">
                      <svg
                          width="72"
                          height="72"
                          viewBox="0 0 72 72"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                          d="M60.3281 13.2188C62.0156 14.9062 63 17.1562 63 19.5469V63C63 68.0625 58.9219 72 54 72H18C12.9375 72 9 68.0625 9 63V9C9 4.07812 12.9375 0 18 0H43.4531C45.8438 0 48.0938 0.984375 49.7812 2.67188L60.3281 13.2188ZM56.25 63H56.1094V22.5H45C42.4688 22.5 40.5 20.5312 40.5 18V6.89062H18C16.7344 6.89062 15.75 7.875 15.75 9.14062V63C15.75 64.2656 16.7344 65.25 18 65.25H54C55.125 65.25 56.25 64.2656 56.25 63ZM39.0938 34.875L42.6094 44.8594L46.2656 33.8906C46.8281 32.0625 48.6562 31.2188 50.4844 31.7812C52.3125 32.3438 53.1562 34.1719 52.5938 36L45.8438 56.25C45.4219 57.6562 44.1562 58.5 42.75 58.5C41.2031 58.5 39.9375 57.6562 39.5156 56.25L36 46.2656L32.3438 56.25C31.9219 57.6562 30.5156 58.5 29.1094 58.5C27.7031 58.5 26.4375 57.6562 26.0156 56.25L19.2656 36C18.7031 34.1719 19.5469 32.3438 21.375 31.7812C23.2031 31.2188 25.0312 32.0625 25.5938 33.8906L29.25 44.8594L32.7656 34.875C33.1875 33.6094 34.4531 32.625 36 32.625C37.4062 32.625 38.6719 33.6094 39.0938 34.875Z"
                          fill="#3082FD"
                          />
                      </svg>
                      <Tag color="blue">*.DOCX</Tag>
                    </Space>
                    <Title level={5}>Research Plan - Template</Title>
                  </Space>
                </Row>
              </Card>
            </motion.div>
          </Col>
        </Row>
      </div>
    );
}
