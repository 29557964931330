import React from "react";
import { CarbonConnect } from "carbon-connect";

import {
  Upload,
  Button,
  Space,
} from "antd";

import styles from "../WidgetStyles.module.css";
import {
  FREE_RESEARCH_ASSETS_LIMIT,
  PAID_RESEARCH_ASSETS_LIMIT
} from "../../utils";

import {
  CSVIcon,
  DOCXIcon,
  PDFIcon,
  MDIcon,
  MP3Icon,
  MP4Icon,
  PPTXIcon,
  RTFIcon,
  TXTIcon,
  XLSXIcon,
  WAVIcon,
  WEBMIcon,
  GDriveIcon,
  DropboxIcon,
  OnedriveIcon,
  WebIcon,
} from "../../utils";
import logo from "../../favicon.png";

const { Dragger } = Upload;

export const UploadWidget = ({ researchId, tokenFetcher, userData, maxFilesCount, maxFileSize, onSuccess, onError, compact, uploadTriggerStyle }) => {
    return(

        <CarbonConnect
          orgName="Research Studio"
          filePickerMode="FILES"
          brandIcon={logo}
          tokenFetcher={tokenFetcher}
          tags={{
            researchId: researchId,
          }}
          children={
            !compact ? (

            <Dragger openFileDialogOnClick={false} className={styles.uploadArea} style={ uploadTriggerStyle }>
              <p className="ant-upload-text">
                <b>Add your research files to get started</b>
              </p>
                <>
                 <p className="ant-upload-hint">
                  .PDF, .DOCX, .CSV, .TXT, .MD, .RTF .PPTX, .XLSX, .MP3, .MP4, .WAV,
                  .WEBM
                </p>
                  <div>
                    <Space
                      size="middle"
                      style={{
                        justifyContent: "center",
                        padding: "20px ",
                        maxWidth: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <img
                        className={styles.integrationIcon}
                        src={GDriveIcon}
                        alt="Google Drive icon"
                      />
                      <img
                        className={styles.integrationIcon}
                        src={DropboxIcon}
                        alt="DropBox icon"
                      />
                      <img
                        className={styles.integrationIcon}
                        src={OnedriveIcon}
                        alt="Onedrive icon"
                      />
                      <img
                        className={styles.integrationIcon}
                        src={WebIcon}
                        alt="Globe resambling web icon"
                      />
                    </Space>
                  </div>
                  <Space
                    size="middle"
                    style={{
                      justifyContent: "center",
                      padding: "20px ",
                      maxWidth: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    <img
                      className={styles.formatIcon}
                      src={PDFIcon}
                      alt="PDF file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={DOCXIcon}
                      alt="DOCX file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={CSVIcon}
                      alt="CSV file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={TXTIcon}
                      alt="TXT file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={MDIcon}
                      alt="MD file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={RTFIcon}
                      alt="RTF file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={PPTXIcon}
                      alt="PPTX file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={XLSXIcon}
                      alt="XLSX file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={MP3Icon}
                      alt="MP3 file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={MP4Icon}
                      alt="MP4 file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={WAVIcon}
                      alt="WAV file format icon"
                    />
                    <img
                      className={styles.formatIcon}
                      src={WEBMIcon}
                      alt="WEBM file format icon"
                    />
                  </Space>
                  <ul style={{ padding: 0 }}>
                    <p>Max file size is: 20 MB</p>
                      <p>
                        {userData.subscriptionStatus === "Premium"
                          ? `Max ${PAID_RESEARCH_ASSETS_LIMIT} files / research`
                          : `Max ${FREE_RESEARCH_ASSETS_LIMIT} files / research during trial period`}
                      </p>
                  </ul>
                </>
            </Dragger>) : (
              <div className={styles.uploadButton} style={ uploadTriggerStyle }>
                + Add more files
              </div>
            )
          }
          maxFileSize={maxFileSize}
          enabledIntegrations={[
            {
              id: "LOCAL_FILES",
              maxFileSize: 20000000,
              allowMultipleFiles: true,
              splitRows:true,
              chunkSize: 500,
              overlapSize: 10,
              filePickerMode: "FILES",
              maxFilesCount: maxFilesCount,
              allowedFileTypes: [
                {
                  extension: "csv",
                  chunkSize: 1200,
                  overlapSize: 10,
                },
                {
                  extension: "txt",
                  chunkSize: 500,
                  overlapSize: 10,
                },
                {
                  extension: "md",
                  chunkSize: 500,
                  overlapSize: 10,
                },
                {
                  extension: "rtf",
                  chunkSize: 500,
                  overlapSize: 10,
                },
                {
                  extension: "pdf",
                  chunkSize: 500,
                  overlapSize: 10,
                },
                {
                  extension: "pptx",
                  chunkSize: 500,
                  overlapSize: 10,
                },
                {
                  extension: "docx",
                  chunkSize: 500,
                  overlapSize: 10,
                },
                {
                  extension: "xlsx",
                  chunkSize: 1200,
                  overlapSize: 10,
                },
                {
                  extension: "mp3",
                  chunkSize: 500,
                  overlapSize: 10,
                },
                {
                  extension: "mp4",
                  chunkSize: 500,
                  overlapSize: 10,
                },
                {
                  extension: "wav",
                  chunkSize: 500,
                  overlapSize: 10,
                },
                {
                  extension: "webm",
                  chunkSize: 500,
                  overlapSize: 10,
                },
              ],
            },
            {
              id: "WEB_SCRAPER",
              chunkSize: 500,
              overlapSize: 10,
            },
            {
              id: "NOTION",
              chunkSize: 500,
              overlapSize: 10,
            },
            {
              id: "ONEDRIVE",
              chunkSize: 500,
              overlapSize: 10,
            },
            {
              id: "DROPBOX",
              chunkSize: 500,
              overlapSize: 10,
            },
            /*
            {
              id: 'GMAIL',
              chunkSize: 500,
            },
                            {
                              id: 'SLACK',
                              chunkSize: 500,
                              overlapSize: 20,
                            },
                            {
                              id: 'WEB_SCRAPER',
                              chunkSize: 500,
                              overlapSize: 20,
                            }, */
            {
              id: "GOOGLE_DRIVE",
              chunkSize: 500,
              overlapSize: 10,
            },
          ]}
          onSuccess={onSuccess}
          onError={onError}
          primaryBackgroundColor="#5e28d1"
          primaryTextColor="#FFFFFF"
          secondaryBackgroundColor="#FFFFFF"
          secondaryTextColor="#FCF8FA"
          allowMultipleFiles={true}
          // entryPoint="LOCAL_FILES"
        ></CarbonConnect>
    );
};
