import React, { useEffect } from "react";
import { Row, Space, message } from "antd";
import logo from "../../logo.png";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { auth, db, get, dbRef, onAuthStateChanged, update, set } from "../../firebase_setup/firebase"
import { DEFAULT_BRANDING } from "../../utils/consts";
import { api } from "../../utils/api";

import styles from "./Login.module.css";
import { last } from "lodash";

export const Login = () => {
  const { googleSignIn, xSignIn, user } = UserAuth();
  const navigate = useNavigate()

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      let emailSent = 0;
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // Create a reference to the user in the database
          const userRef = dbRef(db, "users/" + user.uid);
          const brandingRef = dbRef(db, "users/" + user.uid + "/branding");
          const now = Date.now();

          // Fetch the data at this location
          const snapshot = await get(userRef);
          const brandingSnapshot = await get(brandingRef);

          if (!snapshot.exists()) {
            // If the data does not exist, it's a new user
            // Set their subscription status to 'Free'
            const newUser = {
              email: user.email,
              subscriptionStatus: 'Trial',
              branding: DEFAULT_BRANDING,
              remainingTrial: 3,
              lastUpdated: now,
            };
            await set(userRef, newUser);
            if (emailSent < 1) {
                await api.post('/api/sendWelcomeEmail', { user });
            }
            emailSent++;
            // Since this is a new user, check the welcomeEmailSent property from the newUser object
          } else {
            // If the user is an 'Open Beta' user, transition them to 'Free' (or another status)
            if (
              snapshot.val().subscriptionStatus === "Open Beta" ||
              snapshot.val().subscriptionStatus === "Free"
            ) {
              await update(userRef, {
                subscriptionStatus: "Trial",
                remainingTrial: 3,
                lastUpdated: now,
              });
            }
            if(!snapshot.val().lastUpdated){
              await update(userRef, {
                lastUpdated: now,
              });
            }
            if (!brandingSnapshot.exists()) {
              await set(brandingRef, DEFAULT_BRANDING);
            }
          }

          navigate("/");
        }
      });
    } catch (error) {
      message.error(error);
    }
  };
  
  const handleXLogin = async () => {
    try {
      await xSignIn();
      let emailSent = 0;
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // Create a reference to the user in the database
          const userRef = dbRef(db, "users/" + user.uid);
          // Fetch the data at this location
          const now = Date.now();
          const snapshot = await get(userRef);
          const userEmail = user.providerData[0].email;
          const userObject = {
            displayName: user.displayName,
            email: userEmail,
          };
          if (!snapshot.exists() && userEmail) {
            // If the data does not exist, it's a new user
            // Set their subscription status to 'Free'
            
            const newUser = {
              email: user.email,
              subscriptionStatus: "Trial",
              branding: DEFAULT_BRANDING,
              remainingTrial: 3,
              lastUpdated: now,
            };
            await set(userRef, newUser);
            // Since this is a new user, check the welcomeEmailSent property from the newUser object
            if (emailSent < 1) {
                await api.post('/api/sendWelcomeEmail', { userObject });
            }
            emailSent++;
            // Since this is a new user, check the welcomeEmailSent property from the newUser object
          }
          navigate("/");
        }
      });
    } catch (error) {
      message.error(error);
    }
  };
  
  useEffect(() => {
    if(user && user.uid){
      navigate("/");
    }
  }, [user])
  return (
    <Row align="middle" justify="center" className={styles.loginForm}>
      <div className={styles.logoWrapper}>
        <Link to="/" className={styles.logo}>
          <img alt="Research studio logo light version"src={logo} />
        </Link>
        <h2>Next Level UX Research</h2>
        <p className={styles.loginDisclaimer}>By continuing you agree on our <a target="_blank" className={styles.loginLink} href="https://researchstudio.ai/privacy-policy" rel="noreferrer">Privacy Policy</a> and our <a target="_blank" className={styles.loginLink} href="https://researchstudio.ai/tos" rel="noreferrer">Terms & Conditions</a></p>
      </div>
      <Space align="center" direction="vertical" size="small">
      <button className={styles.googleLogin} onClick={handleGoogleSignIn}>
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 9.61885C18 14.8566 14.4221 18.5451 9.14754 18.5451C4.05738 18.5451 0 14.4877 0 9.39754C0 4.34426 4.05738 0.25 9.14754 0.25C11.582 0.25 13.6844 1.17213 15.2705 2.64754L12.7623 5.04508C9.51639 1.90984 3.46721 4.27049 3.46721 9.39754C3.46721 12.6066 6.0123 15.1885 9.14754 15.1885C12.7623 15.1885 14.127 12.6066 14.3115 11.2418H9.14754V8.10656H17.8525C17.9262 8.58607 18 9.02869 18 9.61885Z"
            fill="#FFFFFF"
          />
        </svg>
        Login with Google
      </button>
      <button className={styles.googleLogin} onClick={handleXLogin}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1273_1885)">
        <path d="M10.5237 7.77566L16.4811 1H15.0699L9.89493 6.88201L5.7648 1H1L7.24693 9.89549L1 16.9999H2.4112L7.87253 10.787L12.2352 16.9999H17M2.92053 2.04126H5.08853L15.0688 16.0098H12.9003" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_1273_1885">
        <rect width="16" height="16" fill="white" transform="translate(1 1)"/>
        </clipPath>
        </defs>
      </svg>

        Login with X
      </button>
      </Space>
    </Row>
  );
};
