import React, { useState, useEffect, useRef, useMemo } from "react";

import {
  FileOutlined,
  DeleteOutlined,
  LockFilled,
  EyeOutlined,
  SearchOutlined,
  CaretDownFilled,
  CaretUpFilled,
  ExclamationCircleTwoTone
} from "@ant-design/icons";
import {
  Col,
  Row,
  Button,
  Input,
  Card,
  Drawer,
  theme,
  Result,
  Typography,
  Space,
  notification,
  Popconfirm
} from "antd";

import { UploadWidget } from "../../widgets"
import { Loader } from "../../components"
import axios from "axios";
import Highlighter from "react-highlight-words";
import { deleteResearchFile } from "../../utils";

const { useToken } = theme;

export const AssetsPanel = ({ 
        researchAssets, 
        researchId, 
        user, 
        userData, 
        maxFileSize, 
        tokenFetcher, 
        onSuccess, 
        onError,
        onUpdate,
        isCorrect }) => {
    const [assets, setAssets] = useState([]);
    const [open, setOpen] = useState(false);
    const [fileText, setFileText] = useState("");
    const [fileName, setFileName] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [fileLContentLoading, setFileContentLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);

    const { token } = useToken();
    const { Text } = Typography;
    
    const searchRef = useRef(null);
    
    const [notificationApi, contextHolder] = notification.useNotification();
    const openNotification = ( message, description, icon ) => {
        notificationApi.open({
            message: message,
            description: description,
            icon: icon,
            duration: 3,
        });
    };

    const handleOpen = async ( file ) => {
        setOpen(true);
        setFileContentLoading(true);
        try {
            const response = await axios.get(file.parsedTextURL);
            setFileText(response.data);
            setFileName(file.title);
            setFileContentLoading(false);
        } catch {
            console.error("Error fetching file text");
            setFileContentLoading(false);
        }
    }
    const increaseActiveIndex = () => {
        if(activeIndex <= count && count > 0){
            setActiveIndex(activeIndex + 1);
        }
    }
    const decreaseActiveIndex = () => {
        if(activeIndex > 0 && count > 0){
            setActiveIndex(activeIndex - 1);
        }
    }
    const handleClose = () => {
        setOpen(false);
        setFileText("");
        setFileName("");
    }
    const countOccurrences = (text, searchWords) => {
        const escapedSearchPhrase = searchWords.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');  
        const regex = new RegExp(`${escapedSearchPhrase}`, 'gi');
        const matches = text.match(regex);
        return matches ? matches.length : 0;
    };
    const handleDelete = async (fileId) => {
        try {
            await deleteResearchFile(user, fileId);
            onUpdate();
        } catch (error) {
            openNotification("Error", "Failed to delete file", <ExclamationCircleTwoTone twoToneColor={token.red} />);
        }
    };
    const handleUpload = () => {
        onSuccess();
        onUpdate();
    }
    const handleInputChange = () => {
        const inputValue = searchRef.current.input.value;
        setTimeout(() => {
            if(inputValue.length > 2){
                setSearchValue(searchRef.current.input.value);
                setCount(countOccurrences(fileText, inputValue));
            } else if(inputValue.length === 0){
                setSearchValue("");
                setCount(0);
                setActiveIndex(0);
            } else {
                setSearchValue("");
                setCount(0);
                setActiveIndex(0);
            }
        }, 100)
    };

    useEffect(( ) => {
      if (researchAssets){
        const assetsArray = researchAssets.map((item, index) => ({
          title: item.fileName,
          key: index,
          id: item.fileId,
          parsedTextURL: item.fileParsedTextURL,
        }));
        setAssets(assetsArray)
      }
    }, [researchAssets])
    
    useEffect(() => {
        const selector = `.textHighlightActive`;
        const highlightedElement = document.querySelector(selector);
    
        // For example, highlightedElements[activeIndex] if you want to scroll to the nth element.
            const elementToScroll = highlightedElement; // Assuming the first one for this example
            if (elementToScroll) {
                elementToScroll.scrollIntoView({
                    behavior: 'smooth', // Optional: Defines the transition animation.
                    block: 'center',    // Optional: Defines vertical alignment.
                    inline: 'nearest'   // Optional: Defines horizontal alignment.
                });
            }
        
    }, [activeIndex])
    return (
        <Row gutter={16}>
            {contextHolder}
            <Col span={24}>
                <div style={{ width: "100%", display: "flex" }}>
                { isCorrect ? (
                    <UploadWidget 
                        researchId={researchId}
                        userData={userData}
                        maxFileSize={maxFileSize}
                        tokenFetcher={tokenFetcher}
                        onSuccess={handleUpload}
                        uploadTriggerStyle={{ width: "100%", maxWidth: "250px", minWidth: "250px", marginRight: "8px"}}
                        onError={onError}
                        compact
                    />
                  ) : (
                    <Result icon={<LockFilled style={{color: token.orange}} />} subTitle="You need to upgrade to our Premium tier in order to upload your files" title="Upgrade to Premium" />
                  )}
                    <Space direction="horizontal" size="small" style={{ width: "100%", overflowX: "auto"}}>
                       {
                        assets.map((item, index) => {
                            return (
                                <Card 
                                    style={{ width: "100%", maxWidth: "250px", minWidth: "250px" }}
                                    key={index}>
                                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr auto" }} wrap={false}>
                                        <div size="small" style={{ display: "grid", gap: "8px", alignItems: "center", width: "100%", gridTemplateColumns: "auto 1fr"}}>
                                            <FileOutlined style={{ color: token.colorPrimary }} />
                                            <Text ellipsis={{ tooltip: item.title }}  style={{ width: "100%", flexBasis:"100%"}} level={5}>{item.title}</Text>
                                        </div>
                                        <div style={{ flex: "none" }}>
                                        <Button 
                                            style={{ padding: "0px 8px" }}
                                            type="link" 
                                            onClick={() => handleOpen(item)}><EyeOutlined />
                                        </Button>
                                        {assets.length > 1 && 
                                            <Popconfirm
                                                title="Are you sure you want to delete this file?"
                                                description="Altering the files will require you to regenerate the data"
                                                onConfirm={() => handleDelete(item.id)}
                                            >
                                                <Button 
                                                    style={{ padding: "0px 8px" }}
                                                    type="link" 
                                                    danger><DeleteOutlined />
                                                </Button>
                                            </Popconfirm>
                                        }
                                        </div>
                                    </div>
                                </Card>
                            )
                        })
                       }
                        </Space>
                </div>
            </Col>
            <Drawer title={fileName} mask={false} width={500} onClose={handleClose} open={open}>
                    <Input
                        ref={searchRef}
                        style={{ position: "sticky", top: 0, background: "#ffffff", borderRadius: "16px"  }}
                        placeholder="Type at least 3 chars to search"                       
                        prefix={<SearchOutlined />}
                        addonAfter={
                            <Space direction="horizontal" size="small">
                                <Button disabled={ activeIndex < 1 ? true : false} onClick={decreaseActiveIndex} size="small" type="link"><CaretUpFilled /></Button> 
                                <span style={{ color: count < 1 && token.colorTextDescription}} >{count > 0 ? activeIndex + 1 : activeIndex} / {count}</span>
                                <Button disabled={activeIndex+1 >= count ? true : false} onClick={increaseActiveIndex} size="small" type="link"><CaretDownFilled /></Button>
                            </Space>}
                        onChange={handleInputChange} />
                    <div className="fileText" style={{ whiteSpace: "pre-wrap", paddingTop: "16px" }}>
                        {
                            fileLContentLoading ? (
                                <Loader loading={fileLContentLoading} size="small" title="Loading file..." />) : (
                                <Highlighter
                                    activeIndex={activeIndex}
                                    activeClassName="textHighlightActive"
                                    highlightClassName="textHighlught"
                                    searchWords={[searchValue]}
                                    autoEscape={true}
                                    textToHighlight={fileText}
                                />
                            )
                        }
                   
                    </div>
            </Drawer>
        </Row>
    );
  }
  