import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Typography,
  Button,
  Space,
  Row,
  theme,
  Tag,
  Carousel,
  notification,
  ConfigProvider,
  Input,
  Popconfirm
} from "antd";
import { ShareAltOutlined, CheckCircleTwoTone, ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { AppBar } from "../../components";
import { DEFAULT_BRANDING } from "../../utils/consts";
import { QuestionsWidget, SentimentWidget, PersonaWidget, StatementsWidget, CompetiorsWidget, CustomerJourneyWidget, InsightsWidget, JTBDWidget, UserStoriesWidget } from "../../widgets";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import styles from "./ResearchPage.module.css";
import { db, dbRef, onValue, update } from "../../firebase_setup/firebase";
import { v4 as uuidv4 } from "uuid";
import MarkdownPreview from '@uiw/react-markdown-preview';

const MemoizedMarkdownPreview = React.memo(({ source, branding }) => {
    return <MarkdownPreview className={styles.reportMarkdown} style={{backgroundColor: "#" + branding.backgroundColor, color: "#" + branding.foregroundColor}} source={source} />;
});
const { useToken } = theme;
ChartJS.register(ArcElement, Tooltip, Legend);

export function ResearchReport({ researchData, researchId, userData, user, canvasMessages }) {
  const [ branding, setBranding ] = useState();
  const [ activeWidgets, setActiveWidgets ] = useState([]);
  const [ shareLink, setShareLink ] = useState();

  const carouselRef = useRef(null);
  const { token } = useToken();
  const { Title, Text } = Typography;
  const [notificationApi, contextHolder] = notification.useNotification();


  const openNotification = ( message, description, icon ) => {
    notificationApi.open({
        message: message,
        description: description,
        icon: icon,
        duration: 3,
    });
  };
  const handleShare = () => {
    const domain = window.location.origin;
    if(researchData && researchData.shareToken){
      setShareLink( `${domain}/share/${researchData.shareToken}` );
    } else {
      const researchRef = dbRef(db, `/research_list/${researchId}`);
      const shareToken = uuidv4();
      update(researchRef, { shareToken: shareToken});
      setShareLink( `${domain}/share/${shareToken}` );
    }
  }
  const handleShareConfirm = () =>{
    navigator.clipboard.writeText(shareLink);
    openNotification(
      "Link Copied",
      "Link copied to clipboard",
      <CheckCircleTwoTone twoToneColor={token.colorSuccess} />
    );
  }
  const creationDate = ( research ) => {
    const dateOptions = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }; 
    const date = research && new Date(parseInt(researchData.createdAt));
    return !isNaN(date) && date.toLocaleString('en-US', dateOptions )
  }
  useEffect(() => {
    const canvasWidgetsRef = dbRef(db, `research_list/${researchId}/canvas_widgets`);
    const unsubscribe = onValue(canvasWidgetsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setActiveWidgets(data);
      }
    });
    return () => unsubscribe(); // Clean up the subscription when the component unmounts
  }, [researchData]);

const canvasWidget = (widgetId, key) => {
  switch (widgetId) {
    case "persona":
      return <PersonaWidget presentation researchId={researchId} researchData={researchData} user={user} summary={researchData.summary} />;
    case "statements":
      return <StatementsWidget presentation user={user} researchId={researchId} researchData={researchData} summary={researchData.summary} />;
    case "cjourney":
      return <CustomerJourneyWidget presentation researchId={researchId} researchData={researchData} user={user} summary={researchData.summary} />;
    case "competitors":
      return <CompetiorsWidget presentation researchId={researchId} user={user} researchData={researchData} summary={researchData.summary} />;
    case "questions":
      return <QuestionsWidget presentation researchId={researchId} user={user} researchData={researchData} summary={researchData.summary} />;
    case "jtbd":
      return <JTBDWidget presentation researchId={researchId} user={user} researchData={researchData} summary={researchData.summary} />;
    case "user-stories":
      return <UserStoriesWidget presentation researchId={researchId} user={user} researchData={researchData} summary={researchData.summary} />;
    default: return null;
  }
}

  useEffect(() => {
    if (userData && userData.branding) {
      setBranding(userData.branding);
    } else {
      setBranding(DEFAULT_BRANDING);
    }
  }, [userData, DEFAULT_BRANDING]);
  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.focus();
    }
  }, [carouselRef, branding])
  const slideStyle = branding && {
    backgroundColor: "#" + branding.backgroundColor,
  }
  const bodyTextStyle = branding && {
      fontSize: "18px",
      color: "#" + branding.foregroundColor,
  };
  const coverCopyrightStyle = branding && {
      fontSize: "14px",
      color: "#" + branding.primaryBg,
      padding: "72px 0px 42px",
      margin: "0px",

  };
  const copyrightStyle = branding && {
      fontSize: "14px",
      color: "#" + branding.foregroundColor,
  };
  const copyrightStyleResponses = branding && {
      fontSize: "14px",
      marginTop: "12px",
      color: "#" + branding.foregroundColor,
  };
  const stickyTitle = branding && {
      position: "sticky",
      top: "0",
      backgroundColor: "#" + branding.backgroundColor,
      zIndex: 1,
      margin: "32px 0px 16px",
      padding: "24px 0",
  }
  const bodyTitleStyle = branding && {
      fontSize: "38px",
      color: "#" + branding.primaryColor,
      margin: "0px",
  };

  const coverStyle = branding && {
      backgroundColor: "#" + branding.primaryColor,
  }
  const coverTitleStyle = branding && {
      fontSize: "72px",
      color: "#" + branding.primaryBg,
  };
  const coverTextStyle = branding && {
      fontSize: "24px",
      color: "#" + branding.primaryBg,
  };
  
  
  return (
    researchData && branding && (
      <div className={styles.presentationPage}>
        <AppBar
          overlay
          title={
            researchData && "Presentation: " + (researchData && researchData.name)
          }
          backButton={true}
          backActionTitle="Back to Research"
          verticalCentering
          backDestination={`/research/${researchId}`}
          extraButton={
            <Popconfirm 
              okText="Copy Link"
              onConfirm= {handleShareConfirm} 
              title="Share research" 
              icon={<ShareAltOutlined style={{ color: token.colorPrimary }} />}
              description={<Input style={{width: '260px'}} 
              value={shareLink} />}>
              <Button type="primary" style={{ marginRight: "42px" }} onClick={handleShare}>
                <ShareAltOutlined />
                Share
              </Button>
            </Popconfirm>
          }
        />
          { researchData && (
            <ConfigProvider
              theme={{
                algorithm: theme.defaultAlgorithm,
                token: {
                  colorBorder: "#" + branding.borderColor,
                  colorBorderSecondary: "#" + branding.borderColor,
                }
              }}>
                {contextHolder}
            <div ref={carouselRef} className={styles.presentation}>
              <Carousel 
                onInit={carouselRef.current && carouselRef.current.focus} 
                fade infinite={false} 
                className={styles.reportCarousel} 
                waitForAnimate={true}
                nextArrow={ <ArrowRightOutlined /> }
                prevArrow={ <ArrowLeftOutlined /> }
                arrows={true}>
                    <div  className={styles.presentationSlide}>
                        <div  style={ coverStyle } tabIndex={-1} ref={carouselRef} autoFocus className={styles.coverSlideContent}>
                            <Text style={ coverTextStyle }>{ creationDate(researchData.createdAt) }</Text> 
                            <Title style={ coverTitleStyle } level={1}>{researchData.name}</Title>
                            { researchData && <Text style={ coverTextStyle }>{ researchData.description }</Text> }
                            { branding.companyName && <div style={ coverCopyrightStyle } className={styles.coverCopyright}>Copyright &copy; { branding.companyName} </div> }
                      </div>
                    </div>
                 { researchData.summary && (
                   <div className={styles.presentationSlide}>
                      <div style={ slideStyle } className={styles.slideContent}>
                            <Row justify="space-between" align="middle" style={ stickyTitle }>
                              <Title style={ bodyTitleStyle } level={1}>Overview</Title>
                              { branding.companyName && <div style={ copyrightStyle } className={styles.copyright}>Copyright &copy; { branding.companyName} </div> }
                            </Row>
                            <Row gutter={32}>
                              <Col span={16}>
                                <Text style={ bodyTextStyle }>{ researchData.summary }</Text>
                              </Col>
                              <Col span={8}>
                              { researchData.sentiment && (
                                <SentimentWidget
                                  presentation
                                  researchId={researchId}
                                  summary={researchData.summary}
                                  researchData={researchData}
                                /> 
                              )}
                              </Col>
                            </Row>
                      </div>
                   </div>)}
                 { researchData.takeaways && (
                   <div className={styles.presentationSlide}>
                      <div style={ slideStyle } className={styles.slideContent}>
                            <Row justify="space-between" align="middle" style={ stickyTitle }>
                              <Title style={ bodyTitleStyle } level={1}>Insights</Title>
                              { branding.companyName && <div style={ copyrightStyle } className={styles.copyright}>Copyright &copy; { branding.companyName} </div> }
                            </Row>
                            <InsightsWidget presentation researchId={researchId} researchData={researchData} user={user} summary={researchData.summary} />
                      </div>
                   </div>)}
                   {  activeWidgets && (
                      activeWidgets.map((widget, index) => {
                        return (
                          <div className={styles.presentationSlide} key={index}>
                            <div style={ slideStyle }  className={styles.slideContent}>
                              <Row justify="space-between" align="middle" style={ stickyTitle }>
                                <Title style={ bodyTitleStyle } level={1}>{ widget.text }</Title>
                                { branding.companyName && <div style={ copyrightStyle } className={styles.copyright}>Copyright &copy; { branding.companyName} </div> }
                              </Row>
                              { canvasWidget(widget.id, index) }
                            </div>
                          </div>
                        )})
                      )
                    }
                   {  canvasMessages && (
                        canvasMessages.map((message, index) => {
                          return (
                            <div className={styles.presentationSlide} key={index}>
                              <div style={ slideStyle }  className={styles.slideContent}>
                                <Row justify="space-between" align="start" style={ stickyTitle }>
                                  <Space direction="vertical">
                                    <Title style={ bodyTitleStyle } level={1}>AI Response</Title>
                                    {
                                      message.external ? 
                                      <Tag color="purple">Context + Broad Knowledge</Tag> : 
                                      <Tag color="orange">Context Only</Tag>}
                                  </Space>
                                  { branding.companyName && <div style={ copyrightStyleResponses } className={styles.copyright}>Copyright &copy; { branding.companyName} </div> }
                                </Row>
                                <MemoizedMarkdownPreview branding={branding} source={message.text} />
                              </div>
                            </div>)
                        })
                      )}
                     <div className={styles.presentationSlide}>
                        <div style={ coverStyle }  className={styles.coverSlideContent}>
                            <Title style={ coverTitleStyle } level={1}>Thank you!</Title>
                            { branding.companyName && <div style={ coverCopyrightStyle } className={styles.coverCopyright}>Copyright &copy; { branding.companyName} </div> }
                        </div>
                      </div>
              </Carousel>
            </div> 
            </ConfigProvider>)
          }
      </div>
    )
  );
}
