import React from 'react'
import styles from './Footer.module.css';
import { Divider } from "antd";
export function Footer() {
    return (
        <div className={styles.footer}>
            <a href="https://researchstudio.ai/help/getting-started" rel="noreferrer" className={styles.footerLink} target="_blank">Getting Started </a>
            <Divider type="vertical"/>
            <a href="https://researchstudio.ai/help/" className={styles.footerLink} rel="noreferrer" target="_blank">Help Center </a>
            <Divider type="vertical"/>
            <a  href="https://researchstudio.ai/privacy-policy"className={styles.footerLink} rel="noreferrer" target="_blank">Privacy Policy </a>
            <Divider type="vertical"/>
            <a href="https://researchstudio.ai/tos" className={styles.footerLink} rel="noreferrer" target="_blank">Terms of Service </a>
            <Divider type="vertical"/>
            <a href="https://researchstudio.ai/refund" className={styles.footerLink} rel="noreferrer" target="_blank">Refund Policy </a>
        </div>
    )
}
